import {
  TogglerState,
  TogglerActionsTypes,
  CHANGE_TOGGLER_STATE
} from './types'

const initialState:TogglerState = {
  isToggled: {
    purchaseOrders: false,
    purchaseOrdersApproval: false,
    commercialOffers: false,
    skuPriceAdditionalContracts: false,
    skuPrices: false,
    skus: false,
    logisticsPrice: false,
    nomenclatures: false,
    regions: false,
    customers: false,
    skuRegionsRelevance: false,
    skuCustomersRelevance: false,
    maxWholesaleMarkups: false,
    stockBalance: false,
    skuProducers: false,
    suppliers: false,
    persistentLog: false,
    dictionaryImport: false,
    inns: false
  }
}

export default function togglerReducer(
  state = initialState,
  action: TogglerActionsTypes
): TogglerState {
  switch (action.type) {
    case CHANGE_TOGGLER_STATE:
      state.isToggled[action.payload] = !state.isToggled[action.payload]
      return { 
          isToggled: {
            ...state.isToggled
          }
      };
    default:
      return state;
  }
}