import * as React from "react";
import { useState, useEffect } from "react";
import { TabbedShowLayout, Tab, useQuery } from "react-admin";
import { Dialog, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../../dataProvider/api";
import Info from "./Info";
import Price from "./Price";
import AddContract from "./AddContract";
import Regional from "./Regional";
import HistoryPrice from "./HistoryPrice";
import StockBalance from "./StockBalance";


const useStyles = makeStyles({
    root: {
        // background: "#2196f3",
        // color: "white"
        padding: '18px 16px',

        '& .MuiTypography-root': {
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '20px'
        }
    },
    tabbedShowLayout:{
        overflowX: "auto",

        // content: {
        //     // padding: '0'
        // },
        height: "50vh",
        '& .MuiTab-wrapper' :{
            fontSize: '14px',
            lineHeight: '20px',
            textTransform: 'none',
        },

        '& .MuiDivider-root + div': {
            padding: 0
        },

        '& .MuiCardContent-root': {
            padding: '36px 40px'
        },

        // inner

        '& .MuiFormControl-root': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            margin: 0,
            marginBottom: 12,
    
        },
        '& .MuiFormControl-root label': {
            color: '#7B7F86',
            marginRight: 32,
            maxWidth: '150px',
            width: '100%',
        },
        '& .MuiFormControl-root label + div': {
            width: 'auto',
            padding: 0,
        },
        '& .MuiTypography-subtitle1': {
            fontSize: '14px',
            lineHeight: '16px',
            marginBottom: 12,
            color: '#7B7F86 !important',
            marginRight: 12
        },
        '& .MuiFormLabel-root':{
            fontSize: '14px',
            lineHeight: '16px',
        },
        '& .MuiInputLabel-shrink': {
            transform: 'none'
        },

        
    },

    price: {
        // background: 'red',

        '& .MuiFormControl-root label': {
            color: '#7B7F86',
            marginRight: 32,
            maxWidth: '300px',
            width: '100%',
        },
    }
  });


const SkuInfo = ({open, handleClose, lotId, regionId}) => {
    const classes = useStyles();
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: `lots/info`,
        payload: { id: lotId }
    });


    return(
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle className={classes.root}>
                {data?.nomenclature}
            </DialogTitle>
            <TabbedShowLayout 
                className={classes.tabbedShowLayout} 
                basePath=""
                resource=""
                record={{...data}}
            >
                <Tab label="Информация">
                    <Info />
                </Tab>
                <Tab label="Прайс" >
                    <Price className={classes.price}/>
                </Tab>
                <Tab label="Региональные">
                    <Regional />
                </Tab>
                <Tab label="История цен" >
                    <HistoryPrice />
                </Tab>
                <Tab label="Остатки">
                    <StockBalance />
                </Tab>
                <Tab label="Доп.соглашения">
                    <AddContract />
                </Tab>
            </TabbedShowLayout>
        </Dialog>
    );
}
export default SkuInfo;