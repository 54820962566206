import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Datagrid, DateField, NumberField, Pagination, ReferenceManyField, TextField } from "react-admin";
import CurrencyField from "../../../components/CurrencyField";
import PercentField from "../../../components/PercentField";
import { entities } from "../../../configuration";

const useStyles = makeStyles({
    // задаем стили для родительского элемента, чтобы работал overflow-x и таблица не выезжала за пределы таба
    wrapper_for_table: {
        overflowX: 'auto'
    }
})

const StockBalance = (props) => {
    const classes = useStyles();

    return (
        <ReferenceManyField perPage={10} pagination={<Pagination />} reference={entities.stockBalance} target="skuId" addLabel={false}  {...props}>
            <div className={classes.wrapper_for_table}>
                <Datagrid rowClick={null}>
                    <CurrencyField source="vitalDrugsPrice" label="Цена реестра, руб. без НДС" />
                    <CurrencyField source="purchasingPriceWithMarkupRatio" label="Цена закупочная с НДС" />
                    <DateField source="expiryDate" label="Срок годности" />
                    <PercentField source="osgPercentage" label="ОСГ в %" />
                    <TextField source="stock" label="Склад" />
                    <TextField source="series" label="Серия" />
                    <NumberField source="count" label="Весь остаток" />
                    <NumberField source="reserved" label="Резерв" />
                    <NumberField source="available" label="Доступно" />
                    <DateField source="validFrom" label="Дата загрузки" />
                </Datagrid>
            </div>
        </ReferenceManyField>
    );
};

export default StockBalance;