import * as downloadFile from 'downloadjs';
import * as React from "react";
import {
    Datagrid,

    DateField, ReferenceField, Show, SimpleShowLayout, TextField,

    TopToolbar, useNotify
} from "react-admin";
import useAdditionalFilterValues from "../../common/useAdditionalFilterValues";
import Pagination from "../../components/DefaultPagination";
import FullNameTextField from '../../components/FullNameTextField';
import { PrimaryButton } from '../../components/GphcUIKit/Button';
import Filter from '../../components/GphcUIKit/Filter';
import List from "../../components/List";
import { AListActions } from "../../components/ListActions";
import { entities } from "../../configuration";
import api from "../../dataProvider/api";
import { FactoryComponents } from '../../common/enums';

const PersistentLogList = props => {
    const filterValues = useAdditionalFilterValues(api.getPersistentLogFilterValues);

    const searchComponentOptions = [
        { type: FactoryComponents.Search, source: "q", alwaysOn: true }
    ]

    const dateComponentsOptions = [
        { type: FactoryComponents.Date, source: "dateFrom", label: "Дата с", alwaysOn: true },
        { type: FactoryComponents.Date, source: "dateTo", label: "Дата по", alwaysOn: true }
    ];

    const filtersComponentsOptions = [
        { type: FactoryComponents.ReferenceAutocomplete, source: "issuerId", label: "Пользователь", reference: entities.users, inputSource: "name", optionText: choice => choice?.fullName ? choice.fullName : '', alwaysOn: true }, ...filterValues
    ]

    const filter = <Filter
            searchComponentOptions={searchComponentOptions}
            dateComponentsOptions={dateComponentsOptions}
            filtersComponentsOptions={filtersComponentsOptions}
            {...props}
        />;

    return (
        <List 
            {...props}
            filters={filter}            
            sort={{ field: 'timestamp', order: 'DESC' }} 
            perPage={25} pagination={<Pagination />} 
            title={props.options.label} 
            actions={<AListActions {...props} />}
            bulkActionButtons={false}
        >
            <Datagrid rowClick="show">
                <DateField source="timestamp" label="Время" showTime />
                <ReferenceField
                    link={false}
                    label="Пользователь"
                    source="issuerId"
                    reference={entities.users}
                >
                    <FullNameTextField source="firstName" />
                </ReferenceField>
                <TextField source="useCase" label="Действие"/>
                <TextField source="paramsDescription" label="Параметры действия"/>
                <TextField source="errorType" label="Тип ошибки"/>
            </Datagrid>
        </List>
    );
};

const Title = (props) => {
    return (<div>Лог: "{props.record?.useCase ?? ""}"</div>)
};

const PersistentLogShow = props => {
    return (
        <Show {...props} title={<Title />} actions={<ShowActions />}>
            <SimpleShowLayout>
                <DateField source="timestamp" label="Время" showTime/>
                <ReferenceField
                    link={false}
                    label="Пользователь"
                    source="issuerId"
                    reference={entities.users}
                >
                    <FullNameTextField source="firstName" />
                </ReferenceField>
                <TextField source="useCase" label="Действие"/>
                <TextField source="paramsDescription" label="Параметры действия"/>
                <TextField source="errorType" label="Тип ошибки"/>
                <TextField source="message" label="Сообщение"/>
            </SimpleShowLayout>
        </Show>
    );
};

const ShowActions = (props) => {
    const { id, isImport } = props?.data ?? {};
    const notify = useNotify();

    if (id == null || !isImport)
        return null;
        
    const download = async () => {
        const result = await api.downloadImportLog(id);
        if (!result.ok) {
            const data = await result.json();
            notify(data.errorMessage ? data.errorMessage : 'Ошибка при загрузке файла', 'warning');
        } else {
            downloadFile(await result.blob(), getImportFileName(result), 'text/csv');
        }
    }

    return <TopToolbar>
        <PrimaryButton onClick={download}>Скачать файл</PrimaryButton>
    </TopToolbar>
};

const getImportFileName = (response) => {
    const header = response.headers.get('content-disposition');
    if (header == null)
        return "import-log.csv";

    if (header && header.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(header);
        if (matches != null && matches[1]) { 
            return matches[1].replace(/['"]/g, '');
        }
    }
}

export default {
    list: PersistentLogList,
    show: PersistentLogShow,
}