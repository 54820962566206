import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from "@material-ui/core";
import { DateField, TextField, Datagrid, Pagination, ReferenceManyField, ReferenceField } from 'react-admin';
import { entities } from '../../configuration';
import FullNameTextField from "../FullNameTextField";
import { makeStyles } from '@material-ui/styles'
import UniversalTextField from './UniversalTextField';
import HistoryChangesDatagrid from './HistoryChangesDatagrid';

const useStyles = makeStyles(({
    dataGrid:{
        "& thead > tr > th": {
            position: "inherit"
        },
        "& .MuiTableCell-sizeSmall": {
            whiteSpace: "break-spaces"
        }
    }
}));

export default (props) => {
    const { data, basePath, resource, buttonText, dialogTitle, parentEntityKind } = props;
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const closeDialog = () => setDialogOpen(false);
    const openDialog = () => setDialogOpen(true);

    const innerProps = { record: data, basePath, resource };

    if(data === undefined){
        return null;
    }
    
    return(
        <React.Fragment>
            <Button onClick={openDialog} variant="contained" color="primary" disableElevation>
                {buttonText}
            </Button>
            <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="lg" fullWidth={true}>
                <DialogTitle>
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                <ReferenceManyField {...innerProps} perPage={10} pagination={<Pagination />} reference={entities.historyOperation} target="parentEntityId" label="" filter={{parentEntityKind: parentEntityKind}}>
                        <Datagrid expand={<ExpandEntityChangesHistory parentEntityId={data.id} parentEntityKind={parentEntityKind}/>} className={classes.dataGrid}>
                            <DateField showTime label="Дата" source="timestamp" />
                            <ReferenceField source="issuerId" reference={entities.users} label="Исполнитель" link={false}>
                                <FullNameTextField source="name"/>
                            </ReferenceField>
                            <TextField label="Операция" source="name" />
                            <TextField label="Подробности" source="paramsDescription" />
                        </Datagrid>
                    </ReferenceManyField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

const ExpandEntityChangesHistory = (props) => {
    const { parentEntityId,  parentEntityKind } = props;
    return(
        <ReferenceManyField {...props} perPage={10} pagination={<Pagination />}  reference={entities.historyChanges} target="historyOperationId" filter={{parentEntityId: parentEntityId, parentEntityKind: parentEntityKind}} label="">
            <Datagrid expand={<ExpandChangesHistory />}>
                <TextField label="Сущность" source="entity" />
                <UniversalTextField label="Идентификатор" source="entityId" GetProps={GetPropsEntity}/>
            </Datagrid>
        </ReferenceManyField>
    );
};

const ExpandChangesHistory = (props) => {
    let dataKey = {};

    props.record?.changes.forEach(element => {
        dataKey[element.id] = element;
    });
    let ids = props.record?.changes.map(c => c.id);

    const dataGridValues = {
        data: dataKey,
        ids: ids
    };
    return(
        <HistoryChangesDatagrid {...dataGridValues} optimized>
            <TextField label="Поле" source="ruProperty"/>
            <UniversalTextField label="Было" source="oldValue" type="old" GetProps={GetPropsChanges}/>
            <UniversalTextField label="Стало" source="newValue" type="new" GetProps={GetPropsChanges}/>
        </HistoryChangesDatagrid>
    );
};

const GetPropsChanges = (props) => {
    if(!props.record)
        return null;

    const { type, reference } = props.record;

    const { oldValue, newValue, ...rest } = props.record;
    let historyChanges : IHistoryChange = rest;

    let { record, source, label, ...withoutRecord } = props;

    let newProps = withoutRecord;
    historyChanges.value = props.type === "old" ? oldValue ?? null : newValue ?? null;
    newProps.record = historyChanges;
    const p = { newProps, reference, type };
    return p;
}

const GetPropsEntity = (props) => {
    if(!props.record)
        return null;

    const { type, reference } = props.record;

    const { entityId, ...rest } = props.record;
    let historyChanges : IHistoryChange = rest;

    let { record, source, label, ...withoutRecord } = props;

    let newProps = withoutRecord;
    historyChanges.value = entityId ?? null;
    newProps.record = historyChanges;
    const p = { newProps, reference, type };
    return p;
}

interface IHistoryChange {
    id : number;    
    entity : string;
    entityId : number;
    property : string;
    value : string | null;
    reference : string;
    field : string;
    ruProperty : string;
    type : string;
}