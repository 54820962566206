import * as React from "react";
import { useEffect, useState } from 'react';
import classnames from "classnames";
import {
    Pagination, ReferenceManyField, useRefresh, SimpleShowLayout, useNotify,
} from "react-admin";
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { useCommercialOfferStyles } from "./../../styles";
import { Button, Typography, Link } from "@material-ui/core";
import { entities } from "../../../../configuration";
import api from "../../../../dataProvider/api";
import ErrorIcon from '@material-ui/icons/Error';
import { useLotStyles } from './lotStyle';
import LotsGrid from './LotsGrid';



const equals = (a, b) => a == b || (a.length === b.length && a.every(function (value, index) { return value === b[index] }));

const LotsPart = (props) => {
    const classes = useLotStyles();
    const [editMode, setEditMode] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);

    const { record: { hasUpdates, updatesShown, hasAdditionalLots } } = props;
    const passSelectedIds = (ids) => {
        if (ids != null && selectedIds == null || !equals(ids, selectedIds))
            setSelectedIds(ids);
    }

    return (
        <SimpleShowLayout {...props}>
            <EditModePanel hasUpdates={hasUpdates} selectedIds={selectedIds} updatesShown={updatesShown} editMode={editMode}
                orderId={props?.record?.id} setEditMode={setEditMode} hasAdditionalLots={hasAdditionalLots}/>
            <ReferenceManyField
                filter={{ NomenclatureMapStatus: 1, includeNotActive: editMode }}
                perPage={10}
                pagination={<Pagination />}
                reference={entities.lots}
                target="purchaseOrderId"
                label=""
                className={classnames("overflow-left-grid", classes.grid)}
                sort={{ field: 'externalSourceUpdateDate', order: 'ASC' }}
            >
                <LotsGrid editMode={editMode} ordersProps={props} passSelectedIds={passSelectedIds} />
            </ReferenceManyField>
        </SimpleShowLayout>
    );
};



const EditModePanel = props => {
    const { hasUpdates, updatesShown, setEditMode, editMode, selectedIds, orderId, hasAdditionalLots } = props;
    const classes = useCommercialOfferStyles();
    const notify = useNotify();
    const refresh = useRefresh();

    const [isActiveSaveButton, changeStateOfActiveButton] = useState<boolean>(false);
    const setStateOfActiveButton = () => {
        // active : disabled
        selectedIds.length > 0 ? changeStateOfActiveButton(true) : changeStateOfActiveButton(false)
    };

    useEffect(() => {
        setStateOfActiveButton();
    }, [selectedIds]) 

    if (!hasUpdates && !hasAdditionalLots)
        return null;

    const submit = async () => {
        const result = await api.activateLots(orderId, selectedIds);
        if (result.succeeded)
            notify('Лоты актуализированы');
        else if (result.errorMessage != null)
            notify('Ошибка: ' + result.errorMessage, 'warning');
        else
            notify('Не удалось актуализировать лоты', 'warning');

        setEditMode(false);
        refresh();
    }

    if (editMode)
        return <div>
            <Typography>Для актуализации лотов, отметьте их в столбце <strong>Актуальность</strong> и сохраните изменения</Typography>
            <div className={classes.LotRelevanceWrap}>
                <Button disabled={!isActiveSaveButton} onClick={submit} variant="contained" 
                    color="primary" endIcon={<DoneIcon />} disableElevation>Сохранить</Button>
                <Button onClick={() => setEditMode(false)} variant="outlined" color="secondary" 
                    endIcon={<CloseIcon />} disableElevation>Отменить</Button>
            </div>
        </div>;

    if (updatesShown) 
       return   <div>
                    <Typography><span className={classes.blockOfText}>Обновления учтены. {hasAdditionalLots ? "Доступны дополнительные лоты." : ""}</span>
                        <Link style={{ cursor: 'pointer' }} onClick={() => setEditMode(true)}>Перейти в режим управления обновлениями.</Link>
                    </Typography>
                </div>;

    return  <div className={classes.detailsHeaderInfo}>
                <Typography><span className='warning-icon'><ErrorIcon /></span><span className={classes.blockOfText}>{hasUpdates ? "Доступны обновления по аукциону. " : ""}{hasAdditionalLots ? "Доступны дополнительные лоты." : ""} </span>
                    <Link style={{ cursor: 'pointer' }} onClick={() => setEditMode(true)}>Перейти в режим управления обновлениями.</Link>
                </Typography>
            </div>;
}



export default LotsPart;