export const LotType = {
  MonoLot: 0,
  Polylot: 1,
};

export const NomenclatureState = {
  NotMapped: 0,
  Mapped: 1,
  Ignored: 2,
};

export const PurchaseOrderLotType = {
    None: 0,
    Mono: 1,
    Collection: 2,
}

export const IsActualStatus = {
  Actual: 0,
  NotActual: 1,
};

export const MappedAreaStatus = {
  NotMapped: 0,
  Mapped: 1,
};

export const MaxMarkupType = {
  LessOrEqual50MaxMarkup: 0,
  Between50And500MaxMarkup: 1,
  Greater500MaxMarkup: 2
}

export enum LotPriceApprovalStatus {
    None,
    Required,
    OnApproval,
    Declined,
    Approved,
}

export enum OrderManagerStatus {
    InWork,
    PartipishiantChoise,
    BadNMC,
    BadTZ,
    NoTime,
    BadLotStatus,
    Defect,
    NoGuaranteedDelivery,
    Rejected,
}

export enum WriteActionType {
    Noop,
    Find,
    Skip,
    Update,
    Insert,
}

export const DosageUnit = {
  Mg: 0,
  Ml: 1,
};

export const LotResultStatus = {
  Won: 0,
  Lost: 1,
  RefusedParticipateNotPassByMinimumPrice: 2,
  RefusedParticipateNotPassByTz: 3,
  RefusedParticipateNotPassByDateSubmission: 4,
  RefusedParticipateNotPassByProductStatus: 5,
  LostRejectedApplication: 6,
  Undefined: 7,
};


export const PurchaseOrderInitialStatus = {
  New: 0,
  Viewed: 1,
  Won: 2,
  Lost: 3,
  BeingPrepared: 4,
  Rejected: 5,
  RefusedParticipateNotPassByMinimumPrice: 6,
  RefusedParticipateNotPassByTz: 7,
  RefusedParticipateNotPassByDateSubmission: 8,
  RefusedParticipateNotPassByProductStatus: 9,
  InProgress: 10,
  Completed: 11
};

export const PurchaseOrderFinalStatus = {
  NotSet: 0,
  NoApplications: 1,
  Cancelled: 2,
  NoContract: 3,
  NoProducerInContract: 4,
  PurchaseFromSingleSupplier: 5,
  PurchaseFromSingleSupplierNoContract: 6,
  Won: 7,
  Lost: 8,
  RefusedParticipateNotPassByMinimumPrice: 9,
  RefusedParticipateNotPassByTz: 10,
  RefusedParticipateNotPassByDateSubmission: 11,
  RefusedParticipateNotPassByProductStatus: 12,
  LostRejectedApplication: 13,
  Undefined: 14
};

export enum PurchaseOrderPriceApprovalStatus {
  NoNeed,
  NeedApproval,
  OnApproval,
  Approved,
  PartiallyApproved,
  Rejected,
};

export enum PurchaseOrderPriceApprovalCommonStatus {
  NoNeed,
  NeedApproval,
  OnApproval,
  FirstCommercialOfferApproved,
  SecondCommercialOfferApproved,
  Approved,
  PartiallyApproved,
  Rejected,
};

export enum CommercialOfferType {
  First,
  Second
};

export enum YesOrNoStatus {
  Yes,
  No,
};

export enum RolesNumber {
  Admin,
  Manager,
  FederalManager,
  Observer,
};

export enum HistoryFieldType {
  IdDate = "idDate",
  String = "string",
  CommercialOfferType = "commercialOfferType",
  DosageUnitType = "dosageUnitType",
  Date = "date",
  Number = "number",
  Currency = "currency",
  Boolean = "boolean",
  Percent = "percent",
  LotResultStatusChoices = "lotResultStatusChoices",
  PurchaseOrderLotTypeChoices = "purchaseOrderLotTypeChoices",
  LotPriceApprovalStatusChoices = "lotPriceApprovalStatusChoices",
  PurchaseOrderProcessingStatusChoices = "purchaseOrderProcessingStatusChoices",
  PurchaseOrderResultStatusChoices = "purchaseOrderResultStatusChoices",
  PurchaseOrderPriceApprovalCommonChoices = "purchaseOrderPriceApprovalCommonChoices",
  PurchaseOrderPriceApprovalStatus = "purchaseOrderPriceApprovalStatus"
};


export enum PurchaseOrderPriceApprovalStatusHistoryChange {
  NoNeed,
  NeedApproval,
  OnApproval,
  Approved,
  PartiallyApproved,
  Declined,
};

export enum PurchaseOrderStatusHistoryChange {
  None,
  Completed,
  СompletedWithSingleParticipant,
  Execution,
  ExecutionComplete,
  UnderReview,
  FailedNoParticipants,
  FailedAllDeclined,
  Cancelled,
  Planing,
};

export const QbParamType = {
  Int : 11,
  Int64 : 12,
  Boolean : 3,
  DateTime : 6,
  Decimal : 7,
  Double : 8,
  String : 16,
  Object : 13,
  AnsiString : 0
};

export const ParamType = {
  Int : 'Int',
  Int64 : 'Int64',
  Boolean : 'Boolean',
  DateTime : 'DateTime',
  Decimal : 'Decimal',
  Double : 'Double',
  String : 'String',
  Object : 'Object',
  AnsiString : 'AnsiString',
  Int32 : 'Int32'
};

export enum FactoryComponents {
  Select = 'select',
  Date = 'date',
  ReferenceInputSelect = 'referenceInputSelect',
  BooleanField = 'booleanField',
  ReferenceAutocomplete = 'referenceAutocomplete',
  Search = 'search',
  Quick = 'quick',
  NullBoolean = 'nullBoolean',
  CustomFilterTopBar = 'customFilterTopBar',
  Inn = 'inn',
  Customer = 'customer',
  FederalDistrict = 'federalDistrict',
  Region = 'region',
  Supplier = 'supplier',
  AreaOfResponsibility = 'areaOfResponsibility',
  Sku = 'sku',
};