import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import {
    BooleanField, Datagrid, DateField,
    fetchEnd, fetchStart, NumberField,
    NumberInput, Pagination, ReferenceField,
    ReferenceInput, ReferenceManyField, required, SelectField, Show,
    SimpleForm, SimpleShowLayout,
    Tab, TabbedShowLayout, TextField, TopToolbar,
    useNotify, useRefresh
} from "react-admin";
import { useDispatch } from 'react-redux';
import { NomenclatureStateChoices } from '../../common/choices';
import { NomenclatureState } from "../../common/enums";
import { useGridFixedColumnStyles } from "../../common/helpers";
import Filter from '../../components/GphcUIKit/Filter';
import SelectInput from "../../components/GphcUIKit/SelectInputSingle";
import List from "../../components/List";
import { AListActions } from "../../components/ListActions";
import { entities } from "../../configuration";
import api from "../../dataProvider/api";
import { ignoreComment } from './../../enums';
import ExpandShow from './ExpandMenu';
import { IgnoreButton } from './IgnoreButtons';
import { FactoryComponents } from '../../common/enums';

const useStyles = makeStyles({
    actionBar: {
        "& > button": {
            marginLeft: 8,
            background: "rgba(0, 155, 195, 0.12)"
        }
    }
});

const NomenclatureList = props => {
    const classes = useGridFixedColumnStyles(2);

    return (
        <List {...props} 
            bulkActionButtons={false} 
            filters={<Filter
                searchComponentOptions={[
                    { type: FactoryComponents.Search, source: "q", alwaysOn: true }
                ]}
                filtersComponentsOptions={[
                    { type: FactoryComponents.Select, source: "mappingState", label: "Cтатус сопоставления", choices: NomenclatureStateChoices, alwaysOn: true, isMultiple: false },
                    { type: FactoryComponents.Sku, alwaysOn: true, sort:{ field: 'nomenclature', order: 'ASC' }, perPage: 300 },
                    { type: FactoryComponents.ReferenceInputSelect, source: "innId", label: "МНН", reference: entities.inns, inputSource: "name", alwaysOn: true, filter: { actualBySkuPrice: true }, sort:{ field: 'name', order: 'ASC' }, perPage: 300  },
                ]}
                defaultFilters={{ mappingState: [NomenclatureState.NotMapped] }}
                {...props}
            />} 
            title={props.options.label}
            actions={<AListActions {...props} />}>
            <Datagrid rowClick="show" expand={<ExpandShow />} sort={{ field: 'skuId', order: 'DESC' }} className={classes.dataGrid}>
                <ReferenceField source="skuId" allowEmpty reference="skus" label="SKU"><TextField source="nomenclature" /></ReferenceField >
                <TextField source="innName" label="МНН" />
                <TextField source="dosageForm" label="ЛФ" />
                <TextField source="dosageFormShort" label="ЛФ1" />
                <TextField source="dosage" label="Дозировка" />
                <TextField source="dosageShort" label="Дозировка1" />
                <NumberField source="ampuleVolume" label="Мл/Мг" />
                <NumberField source="dosageQuantity" label="Кол-во в потр. упаковке" />
                <SelectField source="mappingState" label="Cтатус сопоставления" choices={NomenclatureStateChoices} sortable={false} />
            </Datagrid>
        </List>
    );
};

const Title = (props) => {
    return (<div>Номенклатура "{props.record?.innName ?? ""}"</div>)
};

const NomenclatureShowActions = ({ data, ...rest}) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const dispatch = useDispatch();
    const classes = useStyles();
    
    const submit = async (ampuleVolume, skuId) => {
        dispatch(fetchStart());
        try {
            const result = await api.mapNomenclature(data.id, ampuleVolume, skuId);
            if (result.succeeded) {
                refresh();
                notify("Номенклатура сопоставлена");
            }
            else {
                refresh();
                notify("Номенклатура не сопоставлена", "warning");
            }  
        } finally {
            dispatch(fetchEnd());
        }
    };

    if(!data)
        return null;

    return (
        <TopToolbar className={classes.actionBar}>
            <MapDialog submit={submit} props={{record:data, ...rest}} />
            <IgnoreDialog  data={data}/>
        </TopToolbar>
    );
}

const NomenclatureShow = props => (
    <Show {...props} title={<Title />} actions={<NomenclatureShowActions {...props} />}>
        <TabbedShowLayout>
            <Tab label="Номенклатура">
                <SimpleShowLayout>
                    <ReferenceField source="skuId" allowEmpty reference={entities.skus} label="SKU GPHC"><TextField source="nomenclature" /></ReferenceField >
                    <TextField source="innName" label="МНН" />
                    <TextField source="originalSpecification" label="Оригинал" />
                    <TextField source="dosageForm" label="ЛФ" />
                    <TextField source="dosageFormShort" label="ЛФ1" />
                    <TextField source="dosage" label="Дозировка" />
                    <TextField source="dosageShort" label="Дозировка1" />
                    <NumberField source="ampuleVolume" label="Мл/Мг" />
                    <NumberField source="dosageQuantity" label="Кол-во в потр. упаковке" />
                    <BooleanField source="ignore" label="Игнорируется" />
                    <BooleanField source="mapped" label="Сопоставлен" />
                    <DateField source="validFrom" allowEmpty showTime label="Дата сопоставления" />
                    <SelectField source="ignoreComment" label="Комментарий" allowEmpty choices={ignoreComment} />
                </SimpleShowLayout>
            </Tab>
            <Tab label="История сопоставлений">
                <ReferenceManyField perPage={10} pagination={<Pagination />} reference={entities.nomenclatureSkuMaps} target="productNomenclatureId" label="История сопоставлений" sort={{ field: 'validFrom', order: 'ASC' }}>
                    <Datagrid>
                    <DateField label="Начало периода" source="validFrom" />
                        <ReferenceField link="show" label="SKU GPHC" source="skuId" reference={entities.skus}>
                            <TextField source="nomenclature" />
                        </ReferenceField>
                        <BooleanField label="Игнорируется" source="ignore" />
                        <DateField label="Конец периода" source="validTo" />
                        <ReferenceField link="show" label="Кто назначил" source="issuerId" reference={entities.users}>
                            <TextField source="firstName" />
                        </ReferenceField>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const IgnoreDialog = ({data}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };
    return (
      <>
        <Button variant="text" color="primary" onClick={handleClickOpen}>
          Игнорировать
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="map-dialog-title"
          aria-describedby="map-dialog-description"
        >
          <DialogTitle id="map-dialog-title">Выберите причину игнорирования</DialogTitle>
          <DialogContent>
            <DialogContentText id="map-dialog-description" >
                <IgnoreButton addLabel={true} label="Не подходит по ЛФ" record={data} ignoreCommentValue={0} color="primary" variant="outlined" style={{marginRight: 8}}/>
                <IgnoreButton addLabel={true} label="Не подходит по Дозировке" record={data} ignoreCommentValue={1} color="primary" variant="outlined" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>
      </>
    );
  }


const MapDialog = ({submit, props}) => {
    const skuIdProp = props.record.skuId;
    const [open, setOpen] = React.useState(false);
    const [skuId, setSkuId] = useState(props.record.skuId ?? 0);
    const [ampuleVolume, setAmpuleVolume] = useState(props.record.ampuleVolume ?? -1);

    useEffect(() => {
        setSkuId(props.record.skuId ?? 0);
    },[skuIdProp])

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleCloseAndSubmit = () => {
        submit(ampuleVolume, skuId);
        setOpen(false);
    };
    
    const handleClose = () => {
        setOpen(false);
    };
    return (
      <>
        <Button variant="text" color="primary" onClick={handleClickOpen}>
          Сопоставить
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="map-dialog-title"
          aria-describedby="map-dialog-description"
        >
          <DialogTitle id="map-dialog-title">Сопоставить</DialogTitle>
          <DialogContent>
            <DialogContentText id="map-dialog-description">
                <SimpleForm toolbar={null} {...props}>
                    <ReferenceInput
                        onChange={(e) => setSkuId(e.target.value)}
                        label="SKU" 
                        source="skuId" 
                        reference={entities.skus}
                        filter={{ innId: props.record.innId }} 
                    >
                        <SelectInput optionText="nomenclature" useLabelAsPlaceholder/>
                    </ReferenceInput>
                    <NumberInput 
                        label="Мг/Мл"
                        placeholder="Мг/Мл"
                        source="ampuleVolume" 
                        validate={required()} 
                        onChange={e => setAmpuleVolume(Number.parseFloat(e.target.value))}
                    />
                </SimpleForm>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Отмена
            </Button>
            <Button onClick={handleCloseAndSubmit} color="primary" autoFocus disabled={ !skuId || ampuleVolume < 0}>
              Сопоставить
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

export default {
    list: NomenclatureList,
    show: NomenclatureShow
}