import * as React from "react";
import {
    BooleanField, Datagrid, DateField, NumberField, ReferenceField, TextField
} from "react-admin";
import { IsVeDrugChoices } from '../../common/choices';
import { useGridFixedColumnStyles } from "../../common/helpers";
import useAdditionalFilterValues from "../../common/useAdditionalFilterValues";
import CurrencyField from "../../components/CurrencyField";
import Pagination from "../../components/DefaultPagination";
import Filter from '../../components/GphcUIKit/Filter';
import List from "../../components/List";
import { AListActions } from '../../components/ListActions';
import PercentField from "../../components/PercentField";
import { entities } from "../../configuration";
import api from "../../dataProvider/api";
import { FactoryComponents } from '../../common/enums';

const StockBalanceList = props => {
    const filterValues = useAdditionalFilterValues(api.getStockBalanceFilterValues);
    const classes = useGridFixedColumnStyles(1);

    const filtersComponentsOptions = [
        { type: FactoryComponents.Sku, alwaysOn: true, sort:{ field: 'nomenclature', order: 'ASC' }, perPage: 300 },
        { type: FactoryComponents.ReferenceInputSelect, source: "innId", label: "МНН", reference: entities.inns, inputSource: "name", alwaysOn: true, filter: { actualBySkuPrice: true }, sort:{ field: 'name', order: 'ASC' }, perPage: 300  },
        { type: FactoryComponents.ReferenceAutocomplete, source: "accountingProducerId", label: "Производитель", reference: entities.accountingProducers, inputSource: "name", alwaysOn: true },
        { type: FactoryComponents.ReferenceAutocomplete, source: "accountingSupplierId", label: "Поставщик", reference: entities.accountingSuppliers, inputSource: "name", alwaysOn: true },
        { type: FactoryComponents.Select, source: "isVeDrug", choices: IsVeDrugChoices, label: "Тип ЖНВЛС", alwaysOn: true, isMultiple: false },
        ...filterValues,
    ];

    return <List {...props}
        filters={
            <Filter
                searchComponentOptions={[
                    { type: FactoryComponents.Search, source: "q", alwaysOn: true },
                ]}
                dateComponentsOptions={[
                    { type: FactoryComponents.Date, source: "dateActualTo", label: "Актуальность на", alwaysOn: true },
                ]}
                filtersComponentsOptions={filtersComponentsOptions}
                {...props}
            />
        }
        filter={{ history: false }}
        pagination={<Pagination />}
        perPage={25}
        title={props.options.label}
        actions={<AListActions {...props} />}
        bulkActionButtons={false}
    >
        <Datagrid rowClick={null} className={classes.dataGrid}>
            <ReferenceField source="skuId" reference={entities.skus} label="SKU" link="show">
                <TextField source="nomenclature" />
            </ReferenceField>
            <TextField source="key" label="Код SKU" />
            <TextField source="producerName" label="Производитель" />
            <TextField source="producerCountry" label="Страна происхождения" />
            <TextField source="innName" label="МНН" />
            <TextField source="supplierName" label="Поставщик" />
            <TextField source="vatAmount" label="НДС" />
            <BooleanField source="isVeDrug" label="Тип ЖНВЛП" />
            <CurrencyField source="vitalDrugsPrice" label="Цена реестра, руб. без НДС" />
            <CurrencyField source="purchasingPriceWithMarkupRatio" label="Цена закупочная до вычета КН, руб с НДС" />
            <DateField source="expiryDate" label="Срок годности" />
            <PercentField source="osgPercentage" label="ОСГ в %" />
            <TextField source="stock" label="Склад" />
            <TextField source="series" label="Серия" />
            <NumberField source="count" label="Весь остаток" />
            <NumberField source="reserved" label="Резерв" />
            <NumberField source="available" label="Доступно" />
            <DateField source="validFrom" label="Дата загрузки" />
        </Datagrid>
    </List>
};

export default {
    list: StockBalanceList
};