import * as React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    return {

        PrimaryBtn: {
            "&.MuiButton-root": {
                padding: "8px 20px",
                borderRadius: '8px',
                background: '#009BC3',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '24px',
                textTransform: 'none',
                color: 'white'
            },
        },
        SecondaryBtn: {
            "&.MuiButton-root": {
                padding: "8px 20px",
                borderRadius: '8px',
                background: 'rgba(0, 155, 195, 0.12)',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '24px',
                textTransform: 'none',
                color: '#009BC3'
            },
        },
        SecondaryGrayBtn: {
            "&.MuiButton-root": {
                padding: "8px 20px",
                borderRadius: '8px',
                background: '#F7F7F8',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '24px',
                textTransform: 'none',
                color: '#646469'
            },
        },
        OutlineBtn: {
            "&.MuiButton-root": {
                padding: "8px 20px",
                borderRadius: '8px',
                background: 'transparent',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '24px',
                textTransform: 'none',
                color: '#009BC3',
                border: '1px solid #009BC3'
            },
        },
        FlatBtn: {
            "&.MuiButton-root": {
                padding: "8px 20px",
                borderRadius: '8px',
                background: 'transparent',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '24px',
                textTransform: 'none',
                color: '#009BC3'
            },
        }
    }
});

const Button = (props) => {
    const { defaultClassName, className, children } = props;
    const { className: cn, ...rest } = props;

    const classes = useStyles();
    const resultClassName = classes[defaultClassName] + (className != null ? ` ${className}` : "");
    
    return <MuiButton className={resultClassName} {...rest}>{children}</MuiButton>
}

export const PrimaryButton = (props) => <Button defaultClassName="PrimaryBtn" {...props}></Button>;
export const SecondaryButton = (props) => <Button defaultClassName="SecondaryBtn" {...props}></Button>;
export const SecondaryGrayButton = (props) => <Button defaultClassName="SecondaryGrayBtn" {...props}></Button>;
export const OutlineButton = (props) => <Button defaultClassName="OutlineBtn" {...props}></Button>;
export const FlatButton = (props) => <Button defaultClassName="FlatBtn" {...props}></Button>;
