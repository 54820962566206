import React, { useEffect, useState } from "react";
import { BooleanField, DateField, NumberField, ReferenceManyField, TextField, useNotify } from "react-admin";
import { ParamType } from "../../../common/enums";
import Datagrid from "../../../components/Datagrid";
import Pagination from "../../../components/DefaultPagination";
import { entities } from "../../../configuration";
import api from "../../../dataProvider/api";
import { Parameter } from "./dtoInterfaces";

const GetTokenForQueryBuilder = (id) => {
    return "ReactShow" + id;
}

const ResultGrid = ({ reportId, currentVersionIdSelected, parameters, canExecuteQuery }:
    {
        reportId: number
        currentVersionIdSelected: number,
        parameters: Array<Parameter> | null,
        canExecuteQuery: boolean
    }) => {
    const [fields, setFields] = useState<Array<ColumnInfo> | null>(null);
    const notify = useNotify();

    useEffect(() => {
        (async () => await api.setSqlToAQB(currentVersionIdSelected, GetTokenForQueryBuilder(reportId)))();

        if (canExecuteQuery)
        {
            (async () => {
                const result: ColumnInfo[] = await api.getTypeColumns(currentVersionIdSelected, GetTokenForQueryBuilder(reportId), JSON.stringify(parameters));
                if (typeof(result) === "string")
                {
                    notify(result, 'warning');
                    return;
                }
                setFields(result)
            })();
        };
    }, [currentVersionIdSelected, reportId, canExecuteQuery, parameters])

    if (!canExecuteQuery || fields === null) {
        return (<div></div>)
    }

    return (
        <div style={{ height: "auto", width: "100%", overflow: "auto", minHeight: 300 }}>
            <ReferenceManyField
                perPage={10}
                pagination={<Pagination />}
                reference={entities.reportVersionsQuery}
                addLabel={false}
                basePath="reportVersion"
                filter={{ parameters: JSON.stringify(parameters), token: GetTokenForQueryBuilder(reportId), reportVersionId: currentVersionIdSelected }}
            >
                <Datagrid >
                    {fields.map(e => {
                        const pr = {source: e.name, label: e.name};
                        const type = e.type;
                        switch (type) {
                            case ParamType.DateTime: {
                                return DateTimeField(pr)
                            }
                            case ParamType.Boolean: {
                                return BoolField(pr);
                            }
                            case ParamType.AnsiString:
                            case ParamType.String: {
                                return StringField(pr);
                            }
                            case ParamType.Decimal:
                            case ParamType.Double:
                            case ParamType.Int:
                            case ParamType.Int64:
                            case ParamType.Int32: {
                                return NumField(pr);
                            }
                            default: {
                                return StringField(pr);
                            }
                        }
                    })}
                </Datagrid>
            </ReferenceManyField>
        </div>
    );
}

interface ColumnInfo {
    name: string,
    type: string
}

const StringField = ({source, label}) => <TextField source={source} label={label} />;
const NumField = ({source, label}) => <NumberField source={source} label={label} options={{ maximumFractionDigits: 6 }} />;
const DateTimeField = ({source, label}) => <DateField source={source} label={label} showTime locales="ru-RU" />;
const BoolField = ({source, label}) => <BooleanField source={source} label={label} />;

export default ResultGrid;