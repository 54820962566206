import React, { useState, useEffect } from 'react';
 import { Error } from 'react-admin';
import { Select, MenuItem, makeStyles, ListSubheader, } from '@material-ui/core';
import { connect } from 'react-redux';
import { PUSH_BUBBLE_OBJECT } from '../../store/modules/bubbles/types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
      color: "#aaa"
    }
  }));
  
const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};

const SelectInput = (props) => {
    const { source, choices, optionValue = "id", optionText = "name", placeholder = "Выбрать", label, excludedValue, setFilters, isMultiple = true, renderOrderWithCtrl, currentRenderOrder } = props;
    const { options, onChange, useLabelAsPlaceholder = false, isNotDefaultColor = false, ...rest } = props;
    const { rootState } = props;
    const { router } = rootState;
    const { admin: { resources } } = rootState;
    const pathname = router.location.pathname.split('/')[1]
    const { addChoicesToBubbles } = props;
    const [ ctrlKey, setCtrlKey ] = useState(false);

    useEffect(() => {
        addChoicesToBubbles({
            key: pathname,
            choices: { source: source, label: label, choices: choices }
        })
    }, [])

    const getPlaceholder = () => {
        if (excludedValue != null)
            return excludedValue[optionText];

        return useLabelAsPlaceholder && label != null ? label : placeholder;
    }
    const GetItemName = (choice) => {
        if(typeof(optionText) === 'string')
            return choice[optionText];
        if(typeof(optionText) === 'function')
            return optionText(choice);
        return <Error />
    }

    const renderValue = (value) => {
        if (value == null || value === '')
            return isNotDefaultColor ? <Placeholder>{getPlaceholder()}</Placeholder> : getPlaceholder()

        if (choices == null || choices.length == 0)
            return isNotDefaultColor ? <Placeholder>{getPlaceholder()}</Placeholder> : getPlaceholder()

        const picked = choices.find(i => i[optionValue] == value);
        if (picked == null)
            return isNotDefaultColor ? <Placeholder>{getPlaceholder()}</Placeholder> : getPlaceholder()

        if (typeof optionText == 'string')
            return picked[optionText];
        else if (typeof optionText == 'function')
            return optionText(picked);

        return value;
    }

    const handleChange = (event: React.ChangeEvent<any>) => {
        if (event.target.value === undefined)
            return;

        if (!setFilters) {
            return;
        }

        const localResources = resources[pathname];
        const filters = localResources?.list?.params?.filter;
        const name = event.target.name;
        const valueId = event.target.value.id;
        if (filters[name]) {
            if (isMultiple) {
                if (!filters[name].includes(valueId)) {
                    filters[name].push(valueId)
                }
            } else {
                filters[name] = valueId;
            }
        } else {
            if (isMultiple) {
                filters[name] = [];
                filters[name].push(valueId);
            }
            else {
                filters[name] = {};
                filters[name] = valueId;
            }
        }
        setFilters(filters);
    };

    const renderMenuItems = () => {
        if (renderOrderWithCtrl && ctrlKey) {
            return renderOrderWithCtrl.map((v, i) => {
                if (typeof v == 'string') {
                    return <ListSubheader>{v}</ListSubheader>;
                }
                return (
                    <MenuItem key={i} value={choices[v]}>
                        {GetItemName(choices[v])}
                    </MenuItem>
                )
            });
        }

        if (currentRenderOrder) {
            return currentRenderOrder.map((v: any, i: number) => (
                <MenuItem key={i} value={choices[v]}>
                    {GetItemName(choices[v])}
                </MenuItem>
            ));
        }

        return choices.map((choice: any, i: number) => (
            <MenuItem key={i} value={choice}>
                {GetItemName(choice)}
            </MenuItem>
        ));
    }


    /*
        SelectInput.js:342 Material-UI: You have provided an out-of-range value `[object Object]` for the select (name="sendedCommercialOffer") component.
        Consider providing a value that matches one of the available options or ''.
        The available values are `[object Object]`, `[object Object]`, `[object Object]`.
        Чтобы избежать вышеуказанной ошибки - необходимо обязательно задавать пропс value равный пустой строке:
        <Select value='' />
    */
    return (
        <Select 
            renderValue={renderValue} 
            displayEmpty={true} 
            value='' 
            name={source} 
            onChange={handleChange} 
            style={{ width: '200px' }} 
            onOpen={e => {
                //@ts-ignore 
                if (e.ctrlKey) {
                    setCtrlKey(true);
                }
            }}
            onClose={e => setCtrlKey(false)}
        >
            {renderMenuItems()}
        </Select>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        addChoicesToBubbles: (payload) => dispatch({ type: PUSH_BUBBLE_OBJECT, payload: payload }),
    }
}

export default connect(state => ({ rootState: state }), mapDispatchToProps)(SelectInput);