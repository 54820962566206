import React from 'react';
import { Fragment, useCallback } from 'react';
import { Route, RouteChildrenProps, useHistory } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    toggled: isToggledOn => ({
        width: isToggledOn ? "calc(100% - 430px)" : "100%",
        transition: "width 0.2s"
    }),
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
    },

}));

const ListWithSideBar = props => {
    const history = useHistory();
    const handleClose = useCallback(() => {
        history.push(`/${props.resource}`);
    }, [history]);

    return (
        <div>
            <Route path={`/${props.resource}/:id`}>
                {({ match }: RouteChildrenProps<{ id: string }>) => {
                    const isMatch = !!(
                        match &&
                        match.params &&
                        match.params.id !== 'create'
                    );
                    
                    return (<Wrapp isMatch={isMatch} props={props} handleClose={handleClose} match={match}/>);
                }}
            </Route>
        </div>
    );
}


const Wrapp = ({isMatch, props, handleClose, match}) => {
    const classes = useStyles(isMatch);
    
    return (
        <Fragment>
            {React.cloneElement(React.Children.only(props.list), {classes:{main: classes.toggled, actions: classes.toggled}})}
            <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div onClick={handleClose}></div>
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {isMatch ? (React.cloneElement(React.Children.only(props.show), {
                    id: (match as any).params.id,
                    onCancel: handleClose,
                    ...props
                })) : null}
            </Drawer>
        </Fragment>
    );
}


export default ListWithSideBar;