import React, { useState, useEffect } from "react";
import { SimpleShowLayout, TextField, SaveButton, Toolbar, 
    SimpleForm, NumberInput, required, useRefresh, fetchStart, fetchEnd, useQueryWithStore, ReferenceInput } from "react-admin";
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import { config, entities } from '../../configuration';
import MyReferenceInput from "../../components/ReferenceInput";
import { IgnoreButton } from './IgnoreButtons';
import SelectInput from "../../components/GphcUIKit/SelectInputSingle";
import api from "../../dataProvider/api";

const useStyles = makeStyles({
    flexElem: { display: 'flex' },
    simpleform: { width: '100%' },
    gridContainer: { width: 'auto' },
    tollBar: { 
        display: 'flex', 
        margin: 'auto', 
        justifyContent: 'space-around',
        "& .MuiButton-label": {
            display: "block"
        }
    },
    expandInputs: {  display: 'flex', flexDirection: "column" },
    originalField: {whiteSpace: "pre-wrap", paddingRight: 290},
});

const ExpandShow = props => {
    return (
        <FlexComponent {...props} />
    );
}

const FlexComponent = (props) => {
    const classes = useStyles();
    const refresh = useRefresh();
    const dispatch = useDispatch();

    const submit = async (event) => {
        dispatch(fetchStart());
        try {
            const result = await api.mapNomenclature(props.record.id, event.ampuleVolume, event.skuId);
            if (result.succeeded) 
                refresh();        
        } finally {
            dispatch(fetchEnd());
        }
    };    

    return (
        <Paper >
            <SimpleForm toolbar={<CustomToolbar />} save={submit} {...props}>
                <Grid container justify="center" className={classes.gridContainer}>
                    <Grid item xs={9} className={classes.originalField}>
                        <SimpleShowLayout {...props}>
                            <TextField source="originalSpecification" label="Оригинал" />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={3}>
                        <CompareComp {...props} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Paper>
    );
}

const CompareComp = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.expandInputs}>
            <NumberInput label="Мг/Мл" helperText={false} placeholder="Мг/Мл" source="ampuleVolume" {...props} className={classes.simpleform} validate={required()} />
            <MyReferenceInput
                {...props}
                label="SKU"
                source="skuId"
                optionText="nomenclature"
                reference={entities.skus}
                filter={{ innId: props.record.innId }}
                options={{ maxHeight: 200, maxWeight: 200 }}
                helperText={false}
                className={classes.simpleform}
            >
                <SelectInput label="SKU" useLabelAsPlaceholder optionText="nomenclature" helperText={false} />
            </MyReferenceInput>
        </div>
    );
}

const CustomToolbar = (props) => {
    const classes = useStyles();
    return (
        <Toolbar {...props} className={classes.tollBar}>
            <SaveButton label="Сопоставить" icon={<div></div>}/>
            <IgnoreButton addLabel={true} label="Не подходит по ЛФ" {...props} ignoreCommentValue={0} />
            <IgnoreButton addLabel={true} label="Не подходит по Дозировке" {...props} ignoreCommentValue={1} />
        </Toolbar>
    );
};

export default ExpandShow;