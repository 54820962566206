import * as React from "react";
import { FactoryComponents } from '../common/enums';

export default (loadFunc) => {
    const [filterValues, setFilterValues] = React.useState<any | null>(null);

    React.useEffect(() => {
        const loadFilterValues = async () => {
            const values = await loadFunc();
            setFilterValues(values);
        };

        loadFilterValues();
    }, []);

    if (filterValues == null)
        return [];

    return filterValues.map(v => ({
        type: FactoryComponents.Select, source: v.name,
        label: v.localizedName, choices: v.values.filter(p => p.value != null && p.value != '').map(val => ({ id: val.value, name: val.caption })),
        alwaysOn: true
    }));
}