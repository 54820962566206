import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import {
    AutocompleteInput, BooleanField,
    BooleanInput, Datagrid,
    DateField, DateTimeInput, Edit,
    EditButton, NumberField,
    NumberInput, ReferenceField,
    ReferenceInput, ReferenceManyField,
    required, SaveButton,
    SelectField, SelectInput, Show,
    SimpleForm, SimpleShowLayout,
    Tab, TabbedShowLayout, TextField,
    TextInput, Toolbar,
    TopToolbar, useNotify, useRefresh
} from "react-admin";
import { DosageUnitChoices } from "../../common/choices";
import { isAdmin, useGridFixedColumnStyles } from "../../common/helpers";
import CurrencyField from "../../components/CurrencyField";
import CustomReferenceField from "../../components/CustomReferenceField";
import Pagination from "../../components/DefaultPagination";
import Filter from '../../components/GphcUIKit/Filter';
import HistoryButton from "../../components/History/HistoryButton";
import List from "../../components/List";
import { AListActions } from '../../components/ListActions';
import PercentField from "../../components/PercentField";
import { entities } from "../../configuration";
import api from "../../dataProvider/api";
import { FactoryComponents } from '../../common/enums';

const useStyles = makeStyles({
    inlineAll: {
        "& .MuiFormControl-root": {
            margin: 0,
            display: "flex",
            alignItems: "center",
            marginBottom: 12,
            flexDirection: "row",
            justifyContent: "flex-start"
        },
        "& .MuiFormLabel-root": {
            color: "#7B7F86",
            width: "100%",
            maxWidth: 150,
            marginRight: 32,
        }
    },
    addLabel: {
        '& .MuiFormControlLabel-root':{
            marginLeft: 0
        },
        '& .MuiInputLabel-root':{
            display: "block"
        },
    },
    selectAlign: {
        width: 256,
        '& .MuiInputLabel-root':{
            position: "inherit",
        },
        "& .MuiSelect-select":{
            paddingLeft: 12
        }
    },
    Buttons: {
        paddingTop: 0,
        marginBottom: 35,
        "& .MuiButton-root": {
            padding: "8px 20px",
            marginRight: '16px'
        },
        '& .MuiButton-contained': {
            color: '#646469',
            borderRadius: '8px',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            textTransform: 'none',
            background: '#F7F7F8'
        },
        "& .MuiButton-text": {
            color: '#646469',
            borderRadius: '8px',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            textTransform: 'none',
            background: '#F7F7F8'
        }
    },
})

const SkuList = (props) => {
    const classes = useGridFixedColumnStyles(3);
    return (
        <List
            {...props}
            pagination={<Pagination />}
            perPage={25}
            filters={<Filter
                searchComponentOptions={[
                    { type: FactoryComponents.Search, source: "q", alwaysOn: true },
                ]}
                dateComponentsOptions={[
                    { type: FactoryComponents.Date, source: "dateActualTo", label: "Актуальность на", alwaysOn: true },
                ]}
                filtersComponentsOptions={[
                    { type: FactoryComponents.ReferenceInputSelect, source: "innId", label: "МНН", reference: entities.inns, inputSource: "name", alwaysOn: true, filter: { actualBySkuPrice: true }, sort:{ field: 'name', order: 'ASC' }, perPage: 300  },
                ]}
                {...props}
                />
            }
            title={props.options.label}
            filter={{ history: false }}
            actions={<AListActions {...props} />}
            bulkActionButtons={false}
        >
            <Datagrid rowClick="show" className={classes.dataGrid}>
                <TextField source="key" label="Код SKU" />
                <TextField source="innName" label="МНН" />
                <TextField source="nomenclature" label="Номенклатура" />
                <BooleanField source="isVeDrug" label="Тип ЖНВЛП" />
                <BooleanField source="isActual" label="Актуальность" />
                <NumberField source="dosageVolume" label="мл/мг" />
                <SelectField source="dosageUnit" label="Единицы измерения дозировки" choices={DosageUnitChoices} />
                <TextField source="measurementUnits" label="Единицы измерения" />
                <NumberField source="quantity" label="Количество в упаковке" />
                <BooleanField source="isSt1Available" label="СТ-1" />
                <BooleanField source="isSpAvailable" label="СП" />
            </Datagrid>
        </List>
    )
};

const Title = (props) => {
    return (<div>SKU GPHC "{props.record?.nomenclature ?? ""}"</div>)
};


const SkuShowActions = (props) => {
    const classes = useStyles();

    return (
        <TopToolbar className={classes.Buttons}>
            <HistoryButton buttonText="История SKU" dialogTitle="История SKU" parentEntityKind={2} {...props}/>
            <EditButton basePath={props.basePath} record={props.data}/>
            {isAdmin(props.permissions) && <Actualize record={props.data}/> }
        </TopToolbar>
    );
};


const SkuShow = (props) => {
    const classes = useStyles();
    return (
        <Show {...props} title={<Title />} actions={<SkuShowActions permissions={props.permissions}/>}>
            <TabbedShowLayout >
                <Tab label="SKU">
                    <SimpleShowLayout className={classes.inlineAll}>
                        <TextField source="innName" label="МНН" />
                        <TextField source="key" label="Код SKU" />
                        <TextField source="nomenclature" label="Номенклатура" />
                        <TextField source="pharmaceuticalGroup" label="Фарм. группа" />
                        <BooleanField source="isVeDrug" label="Тип ЖНВЛП" />
                        <NumberField source="dosageVolume" label="мл/мг" />
                        <SelectField source="dosageUnit" label="Единицы измерения дозировки" choices={DosageUnitChoices} />
                        <TextField source="measurementUnits" label="Единицы измерения" />
                        <NumberField source="quantity" label="Количество в упаковке" />
                        <BooleanField source="isSt1Available" label="СТ-1" />
                        <BooleanField source="isSpAvailable" label="СП" />
                        <PercentField source="vatAmount" label="% НДС" />
                        <TextField source="temperatureRegime" label="Температурный режим" />
                        <TextField source="boxUnitsCount" label="Кол-во упаковок" />
                        <TextField source="boxWeight" label="Вес (кг)" />
                        <NumberField source="boxVolume" label="Объем" options={{ maximumFractionDigits: 2 }} />
                        <TextField source="palletUnitsCount" label="Кол-во упаковок" />
                        <TextField source="palletWeight" label="Вес (кг)" />
                        <NumberField source="palletVolume" label="Объем" options={{ maximumFractionDigits: 2 }} />
                        <TextField source="additionalContractNumber" label="Номер ДС" />
                        <DateField source="additionalContractValidFrom" label="Дата начала" showTime />
                        <DateField source="additionalContractValidTo" label="Дата окончания" showTime />
                    </SimpleShowLayout>
                </Tab>
                <Tab label="Номенклатуры">
                    <ReferenceManyField perPage={10} pagination={<Pagination />} reference={entities.nomenclatures} target="skuId" label="Номенклатуры">
                        <Datagrid rowClick="show" >
                            <TextField source="innName" label="МНН" />
                            <TextField source="dosageForm" label="ЛФ" />
                            <TextField source="dosageFormShort" label="ЛФ1" />
                            <TextField source="dosage" label="Дозировка" />
                            <TextField source="dosageShort" label="Дозировка1" />
                            <NumberField source="dosageVolume" label="Мл/Мг" />
                            <NumberField source="dosageQuantity" label="Кол-во в потр. упаковке" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Прайс">
                    <CustomReferenceField filter={{ history: false }} source="id" reference={entities.skuPrices} target="skuId"
                        addLabel={false} link={false}>
                        <SimpleShowLayout className={classes.inlineAll}>
                            <TextField source="markupRatio" label="Размер КН, %" />
                            <CurrencyField source="purchasingPriceWithMarkupRatio" label="Закупочная цена с НДС (до вычета КН)" />
                            <CurrencyField source="purchasingPriceWithoutMarkupRatio" label="Закупочная цена с НДС (после вычета КН)" />
                            <CurrencyField source="planningSellingPrice" label="Плановая продажная цена с НДС" />
                            <PercentField source="planningProfitability" label="Плановая рентабельность, %" />
                            <CurrencyField source="minPrice" label="Мин. цена дна с НДС" />
                            <PercentField source="minPriceProfitability" label="Плановая рентабель-ность при min цене, %" />
                        </SimpleShowLayout>
                    </CustomReferenceField>
                </Tab>
                <Tab label="Логистические прайсы">
                    <ReferenceManyField {...props} perPage={10} pagination={<Pagination />} reference={entities.logisticsPrice} target="skuId" label="">
                        <Datagrid rowClick="show">
                            <ReferenceField source="regionId" label="Регион" reference={entities.regions}>
                                <TextField source="name" />
                            </ReferenceField>
                            <CurrencyField source="price" label="Ст-ть логистики 1 м3 в регион" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="История актуальности">
                    <ReferenceManyField perPage={10} pagination={<Pagination />} sort={{ field: 'validFrom', order: 'ASC' }} reference={entities.skuActualityPeriod} target="skuId" label=" ">
                        <Datagrid>
                            <DateField label="Начало периода" source="validFrom" />
                            <DateField label="Конец периода" source="validTo" />
                            <BooleanField source="isActual" label="Актуальность" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="История бюджетного прайса">
                    <ReferenceManyField perPage={10} pagination={<Pagination />} reference={entities.skuPrices} target="skuId" addLabel={false}  {...props}>
                        <Datagrid rowClick="toggleSelection">
                            <DateField source="validFrom" label="Дата"/>
                            <CurrencyField source="vitalDrugsPrice" label="Цена ЖНВЛП без НДС" />
                            <PercentField source="vatAmount" label="% НДС" />
                            <CurrencyField source="purchasingPriceWithMarkupRatio" label="Закупочная цена с НДС (до вычета КН)" />
                            <CurrencyField source="purchasingPriceWithoutMarkupRatio" label="Закупочная цена с НДС (после вычета КН)" />
                            <TextField source="markupRatio" label="Размер КН, %" />
                            <CurrencyField source="planningSellingPrice" label="Плановая продажная цена с НДС" />
                            <PercentField source="planningProfitability" label="Плановая рентабельность, %" />
                            <CurrencyField source="minPrice" label="Мин. цена дна с НДС" />
                            <PercentField source="minPriceProfitability" label="Плановая рентабель-ность при min цене, %" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};


const Actualize = ({record}) => {
    const notify = useNotify();
    const refresh = useRefresh(); 

    if (record == null)
        return null;
        
    const switchSkuActuality = async () => {
        const { id } = record;

        if (record.isActual) {
            const result = await api.deactualizeSku(id);
            if (result?.succeeded) {
                notify('SKU деактулизировано');
                refresh();
            }
            else {
                notify(`Ошибка: не удалось деактулизировать SKU, причина: ${result?.errorMessage}`, 'warning');
                refresh();
            }
        }
        else {
            const result = await api.actualizeSku(id);
            if (result?.succeeded) {
                notify('SKU актуализировано');
                refresh();
            }
            else {
                notify(`Ошибка: не удалось актулизировать SKU, причина: ${result?.errorMessage}`, 'warning');
                refresh();
            }
        }
    }
    

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={switchSkuActuality}
            disableElevation
        >
            {record.isActual ?  "Деактуализировать" : "Актуализировать" }
        </Button>
    );
};


const SkuEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const SkuEdit = (props) => {
    const classes = useStyles(); 
    return <Edit {...props}>
        <SimpleForm toolbar={<SkuEditToolbar />} className={classes.addLabel} redirect="show">
            <TextInput source="key" label="Код SKU" validate={[required()]} />
            <TextInput source="nomenclature" label="Номенклатура" validate={[required()]} />
            <TextInput source="pharmaceuticalGroup" label="Фарм. группа" />
            <BooleanInput source="isVeDrug" label="Тип ЖНВЛП" validate={[required()]} />
            <NumberInput source="dosageVolume" label="мл/мг" validate={[required()]} />
            <SelectInput source="dosageUnit" label="Единицы измерения дозировки" choices={DosageUnitChoices} validate={[required()]} className={classes.selectAlign}/>
            <NumberInput source="quantity" label="Количество в упаковке" validate={[required()]} />
            <TextInput source="measurementUnits" label="Единицы измерения" />
            <ReferenceInput source="innId" reference={entities.inns} label="МНН" className={classes.selectAlign}>
                <AutocompleteInput source="name" />
            </ReferenceInput>
            <BooleanInput source="isSt1Available" label="СТ-1" />
            <BooleanInput source="isSpAvailable" label="СП" />
            <NumberInput source="vatAmount" label="% НДС" min={0}/>
            <TextInput source="temperatureRegime" label="Температурный режим" />
            <NumberInput source="boxUnitsCount" label="Кол-во упаковок" validate={[required()]}/>
            <NumberInput source="boxWeight" label="Вес (кг)" validate={[required()]}/>
            <NumberInput source="boxVolume" label="Объем" min={0} validate={[required()]}/>
            <NumberInput source="palletUnitsCount" label="Кол-во упаковок" validate={[required()]}/>
            <NumberInput source="palletWeight" label="Вес (кг)" validate={[required()]}/>
            <NumberInput source="palletVolume" label="Объем" min={0} validate={[required()]}/>
            <TextInput source="additionalContractNumber" label="Номер ДС" allowEmpty />
            <DateTimeInput  source="additionalContractValidFrom" label="Дата начала" allowEmpty/>
            <DateTimeInput  source="additionalContractValidTo" label="Дата окончания" allowEmpty/>
        </SimpleForm>
    </Edit>
}

export default {
    list: SkuList,
    show: SkuShow,
    edit: SkuEdit
};
