import { Button } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import * as React from "react";
import {
    BooleanField,
    BooleanInput, Create, Datagrid, DateField,
    ReferenceField, ReferenceInput, ReferenceManyField,
    SimpleForm,SimpleShowLayout, TextField,
    useNotify,useRefresh, useShowController, useUnselectAll
} from "react-admin";
import { IsActualStatusChoices } from "../../common/choices";
import { useGridFixedColumnStyles } from "../../common/helpers";
import useAdditionalFilterValues from "../../common/useAdditionalFilterValues";
import Pagination from "../../components/DefaultPagination";
import AutocompleteInputSingle from "../../components/GphcUIKit/AutocompleteInputSingle";
import Filter from '../../components/GphcUIKit/Filter';
import List from "../../components/List";
import { AListActions } from '../../components/ListActions';
import ListWithSideBar from "../../components/ListWithSideBar";
import { entities } from "../../configuration";
import api from "../../dataProvider/api";
import { FactoryComponents } from '../../common/enums';

const AList = props => {
    const classes = useGridFixedColumnStyles(1);
    const filters = useAdditionalFilterValues(api.getSkuRegionRelevanceFilterValues);
    const refresh = useRefresh();
    const notify = useNotify();
    const unSelect = useUnselectAll();

    const checkResult = (result: any) => {
        if (result.succeeded) {
            refresh();
            notify("Актуальность изменена");
            unSelect(entities.skuRegionsRelevance);
        } else notify("Ошибка: не удалось изменить актуальность", "warning");
    };

    const BulkActualButtons = (props) => {
        const { selectedIds } = props;

        const submit = actual => async (e) => {
            const result = await api.bulkSkuRegionRelevanceChangeActuality(selectedIds, actual);
            checkResult(result);
        };

        return (
            <div>
                <Button variant="outlined" color="primary" onClick={submit(true)}>
                    Актуализировать
                </Button>
                <Button variant="outlined" color="primary" onClick={submit(false)}>
                    Деактулизировть
                </Button>
            </div>
        );
    }

    return <List
        {...props}
        filters={
            <Filter
                searchComponentOptions={[
                    { type: FactoryComponents.Search, source: "q", alwaysOn: true },
                ]}
                dateComponentsOptions={[
                    { type: FactoryComponents.Date, source: "dateActualTo", label: "Актуальность на", alwaysOn: true },
                ]}
                filtersComponentsOptions={[
                    { type: FactoryComponents.ReferenceInputSelect, source: "innId", label: "МНН", reference: entities.inns, inputSource: "name", alwaysOn: true, filter: { actualBySkuPrice: true }, sort:{ field: 'name', order: 'ASC' }, perPage: 300  },
                    { type: FactoryComponents.Sku, alwaysOn: true, sort:{ field: 'nomenclature', order: 'ASC' }, perPage: 300 },
                    { type: FactoryComponents.FederalDistrict, alwaysOn: true },
                    { type: FactoryComponents.Region, alwaysOn: true, perPage: 300 },
                    { type: FactoryComponents.Select, source: "isActual", choices: IsActualStatusChoices, label: "Актуальность", alwaysOn: true, isMultiple: false },
                    ...filters,
                ]}
                {...props}
            />
        }
        filter={{ history: false }}
        pagination={<Pagination />}
        perPage={25}
        bulkActionButtons={<BulkActualButtons />}
        title={props.options.label}
        actions={<AListActions {...props} />}
    >
        <Datagrid rowClick="show" className={classes.dataGrid}>
            <ReferenceField source="skuId" reference={entities.skus} label="SKU" link="show">
                <TextField source="nomenclature" />
            </ReferenceField>
            <ReferenceField source="innId" reference={entities.inns} label="МНН" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="federalDistrictId" reference={entities.federalDistricts} label="ФО" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="regionId" reference={entities.regions} link={false}>
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="actual" label="Актуальность" />
            <DateField source="validFrom" label="Начало периода" />
            <TextField source="comment" label="Комментарии" />
        </Datagrid>
    </List>
};

const SkuRRCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <BooleanInput source="actual" label="Актуальность" />
            <ReferenceInput source="regionId" label="Регион" reference={entities.regions} InputLabelProps={{ shrink: true }} >
                <AutocompleteInputSingle source="name" isNotDefaultColor useLabelAsPlaceholder />
            </ReferenceInput>
            <ReferenceInput source="skuId" reference={entities.skus} label="SKU" InputLabelProps={{ shrink: true }} >
                <AutocompleteInputSingle optionText="nomenclature" isNotDefaultColor useLabelAsPlaceholder />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 92,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
}));

const AShow = ({ ...props }) => {
    const classes = useStyles();
    const controllerProps = useShowController(props);

    if (!controllerProps.record) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    История
                </Typography>
                <IconButton onClick={props.onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleShowLayout
                className={classes.form}
                basePath={controllerProps.basePath}
                record={controllerProps.record}
            >
                <ReferenceManyField
                    perPage={10}
                    pagination={<Pagination />}
                    reference={entities.skuRegionsRelevance}
                    label=""
                    source="skuRegionsRelevanceId"
                    filter={{ regionId: controllerProps.record.regionId, skuId: controllerProps.record.skuId, history: true }}
                    target=""
                    sort={{ field: 'validFrom', order: 'ASC' }}
                >
                    <Datagrid>
                        <DateField source="validFrom" label="Дата загрузки" />
                        <BooleanField source="actual" label="Актуальность" />
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </div>
    );
};

const SkuRRList = props => {
    return (
        <ListWithSideBar
            {...props}
            show={<AShow />}
            list={<AList {...props}
            />}
        />
    );
};


export default {
    list: SkuRRList,
    create: SkuRRCreate,
};