import * as React from "react";
import { Admin, Resource, Sidebar, Layout, AppBar, UserMenu, Login } from "react-admin";
import { entities } from "./configuration";
import { areas, nomenclature, regions, skus, users, stockBalance, accountingProducers,
  orders, customers, lots, skuCustomerRelevance, maxWholesaleMarkups, accountingSuppliers,
  skuRegionRelevance, logisticsPrice, skuPrice, commercialOffer, persistentLog, dictionaryImport,
  skuProducer, suppliers, reports, reportVersions, inns
} from "./entities";
import dataProvider from './dataProvider/';
import i18nProvider from './i18nProvider/';
import { MenuItem, Divider, Link as MuiLink, Typography, Tooltip } from '@material-ui/core';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
import customTheme from './customTheme/customTheme.js'
import { Route, Link } from 'react-router-dom';
import "./styles.css";
import authProvider, { getUserFullName } from "./authProvider";
import { isAdmin, isFederalManager, isObserver } from "./common/helpers";
import { Provider } from 'react-redux';
import createAdminStore from './createAdminStore';
import { createHashHistory } from 'history';
import ResetPassword from "./components/pages/ResetPassword";
import ForgotPassword from "./components/pages/ForgotPassword";
import LoginForm from "./components/pages/LoginForm";
import MenuBookIcon from '@material-ui/icons/MenuBook';

// dependency injection
const history = createHashHistory();

const useAppBarStyles = makeStyles({
  appbarButton: {
    color: "inherit",
    padding: 12,
    borderRadius: "50%",
    height: 24,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  }
});

const useLoginPageStyles = makeStyles({
  loginPage: {
    '&': {
      justifyContent: 'center'
    },
    '& .MuiPaper-root': {
      marginTop: 0
    }
  }
})

// const MyLayout = (props) => <Layout {...props} menu={MyMenu} />;

const StyledSidebar = props => {  
  return (
    <>
      <Sidebar {...props} />
    </>
  );
};

const CustomUserMenu = props => {
  const name = getUserFullName();
  const classes = useAppBarStyles();
  return ( 
    <>
      <Tooltip title="Руководство" >
      <a href="/userManual.pdf"  target="_blank" rel="noopener noreferrer" className={classes.appbarButton} style={{color: "inherit", textDecoration: "none"}}>
        <MenuBookIcon />
      </a>
      </Tooltip>
      <UserMenu label={name} {...props}>
          {name != null && [<MenuItem style={{pointerEvents: 'none'}}>{name}</MenuItem>, <Divider />]}
      </UserMenu>
    </>
  )
}

const StyledAppBar = (props) => {
  const { userMenu, ...rest } = props;

  return (
    <>
      <AppBar userMenu={<CustomUserMenu />} {...rest} />
    </>
  );
}

const CustomLayout = (props) => {
  return (
    <Layout
      {...props}
      sidebar={StyledSidebar}
      appBar={StyledAppBar}
    />
  )
};

const MyLink = React.forwardRef((props: any, ref) => {
  const { href } = props;

  //@ts-ignore
  return <MuiLink href={href} ref={ref}>{props.children}</MuiLink>
});

const MyLoginPage = (props) => {
  const classes = useLoginPageStyles();
  return <Login {...props} className={classes.loginPage}>
      <React.Fragment>
        <LoginForm redirectTo="/"/>
        <Typography style={{padding: '7px', textAlign: "center"}}>
          <Link component={MyLink} to="/forgotPassword">Забыли пароль?</Link>
        </Typography>
      </React.Fragment>
    </Login>
}

const App = () => (
  <Provider store={createAdminStore({
      authProvider,
      dataProvider,
      history,
    })}>
  <StylesProvider injectFirst>
    <Admin 
      history={history} 
      customRoutes={customRoutes} 
      authProvider={authProvider} 
      theme={customTheme} 
      dataProvider={dataProvider}
      loginPage={MyLoginPage} 
      layout={CustomLayout} 
      i18nProvider={i18nProvider}
    >
      {
        permissions => renderResources(permissions)
      }
  </Admin>
  </StylesProvider>
  </Provider>
);

const customRoutes = [
  <Route exact path="/resetPassword" component={ResetPassword} noLayout />,
  <Route exact path="/forgotPassword" component={ForgotPassword} noLayout />
  ,
];

const isFederalManagerOrAdmin = (permissions) => isAdmin(permissions) || isFederalManager(permissions);


const resources = [
  [null, <Resource name={entities.orders} {...orders} options={{ label: "Аукционы" }} />],
  [[isAdmin, isFederalManager], <Resource name={entities.ordersOnApprove} {...orders} options={{ label: "Аукционы на согласовании", onApproval: true }} />],
  [null, <Resource name={entities.commercialOffers} {...commercialOffer} options={{ label: "Ком. предложения" }} />],
  [null, <Resource name={entities.skuPriceAdditionalContracts} {...skuPrice} options={{ label: "Доп. соглашения" }} />],
  [null, <Resource name={entities.skuPrices} {...skuPrice} options={{ label: "Бюджетный прайс" }}/>],
  [[isAdmin], <Resource name={entities.skus} {...skus} options={{ label: "SKU GPHC" }} />, <Resource name={entities.skus} show={skus.show} options={{ label: "SKU GPHC" }} /> ],
  [null, <Resource name={entities.logisticsPrice} {...logisticsPrice} options={{ label: "Весогабариты и логистика" }}/>],
  [[isAdmin], <Resource name={entities.nomenclatures} {...nomenclature} options={{ label: "Номенклатуры ИАС" }} />],
  [null, <Resource name={entities.regions} {...regions} options={{ label: "Регионы" }} />],
  [[isAdmin], <Resource name={entities.customers} {...customers} options={{ label: "ЛПУ" }} />, <Resource name={entities.customers} show={customers.show} list={customers.list} options={{ label: "ЛПУ" }} />],
  [[isAdmin], <Resource name={entities.areasOfResponsibility} {...areas} options={{ label: "Зоны ответственности" }} />],
  [[isAdmin], <Resource name={entities.skuRegionsRelevance} {...skuRegionRelevance} options={{ label: "Актуальные SKU по регионам" }} />, <Resource name={entities.skuRegionsRelevance} list={skuRegionRelevance.list} options={{ label: "Актуальные SKU по регионам" }} />],
  [[isAdmin], <Resource name={entities.skuCustomersRelevance} {...skuCustomerRelevance} options={{ label: "Актуальные SKU по ЛПУ" }} />, <Resource name={entities.skuCustomersRelevance} list={skuCustomerRelevance.list} options={{ label: "Актуальные SKU по ЛПУ" }} />],
  [null, <Resource name={entities.maxWholesaleMarkups} {...maxWholesaleMarkups} options={{label: "Пред. размер опт. надбавок"}}/>],
  [null, <Resource name={entities.stockBalance} {...stockBalance} options={{label: "Остатки"}} />],
  [null, <Resource name={entities.inns} {...inns} options={{ label: "МНН" }} /> ],
  [[isAdmin], <Resource name={entities.users} {...users} options={{ label: "Пользователи" }} />],
  [[isAdmin], <Resource name={entities.dictionaryImport} {...dictionaryImport} options={{label: "Импорт справочников"}} />],
  [[isAdmin], <Resource name={entities.persistentLog} {...persistentLog} options={{ label: "Системный журнал" }} />],
  [[isAdmin], <Resource name={entities.skuProducers} {...skuProducer} options={{ label: "Производители" }} />],
  [[isAdmin], <Resource name={entities.suppliers} {...suppliers} options={{ label: "Поставщики" }} />],
  [[isAdmin, isObserver], <Resource name={entities.reports} {...reports} options={{ label: "Настраиваемые отчеты" }} />],
  [[isAdmin], <Resource name={entities.reportVersions} {...reportVersions} />]
];

const commonResources = [
  <Resource name={entities.accountingSuppliers } />,
  <Resource name={entities.lots} {...lots} />,
  <Resource name={entities.federalDistricts} />,
  <Resource name={entities.areasOfResponsibilityCustomers} />,
  <Resource name={entities.areasOfResponsibilityRegions} />,
  <Resource name={entities.areasOfResponsibilityUsers} />,
  <Resource name={entities.nomenclatureSkuMaps} />,
  <Resource name={entities.producers} />,
  <Resource name={entities.accountingProducers} />,
  <Resource name={entities.lotPriceApprovals} />,
  <Resource name={entities.supplierEmails} />,
  <Resource name={entities.commercialOfferLotsPrices} />,
  <Resource name={entities.pricesApprovals} />,
  <Resource name={entities.skuActualityPeriod} />,
  <Resource name={entities.historyOperation} />,
  <Resource name={entities.historyChanges} />,
  <Resource name={entities.xlsConfigs} />,
  <Resource name={entities.reportVersionsQuery} />,
];

const renderResources = (permissions) => {
  const authorized = resources.map(r => {
    const [predicates, resource, elseResource] = r as any;
    if (predicates == null || predicates?.some(p => p(permissions)))
      return resource;
    else if (elseResource) {
      return elseResource;
    }

    const { props: { list, show, edit, options, ...restProps } } = resource;

    return React.createElement(resource.type, restProps);
  });

  return [...authorized, ...commonResources];
}

export default App;
