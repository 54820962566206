import { Button } from "@material-ui/core";
import * as React from "react";
import {
    Datagrid, DateField, Create, Edit, EditButton,
    ReferenceField, ReferenceInput, ReferenceManyField, FormDataConsumer,
    required, SaveButton, SelectInput, Show, SimpleForm, SimpleShowLayout,
    Tab, TabbedShowLayout, TextField, TextInput, Toolbar, TopToolbar, email,
    useNotify, useRefresh, usePermissions, BooleanField, BooleanInput, useRedirect, ShowButton, useEditController
} from "react-admin";
import { isAdmin } from "../../common/helpers";
import Pagination from "../../components/DefaultPagination";
import { AListActions } from '../../components/ListActions';
import { entities } from "../../configuration";
import api from "../../dataProvider/api";
import MapBulkButtonLayout from "../../layouts/bulkButtons/map/MapBulkButtonLayout";
import { RolesChoices } from "../../common/choices";
import { RolesNumber } from "../../common/enums";
import { makeStyles } from "@material-ui/styles";
import ChipListField from "../../components/GphcUIKit/ChipListField";
import HistoryButton from "../../components/History/HistoryButton";
import List from "../../components/List";

const useStyles = makeStyles({
    addLabel: {
        '& .MuiInputLabel-filled': {
            display: "block"
        },
    },
    selectAlign: {
        width: 256,
        "& .MuiInputLabel-filled": {
            position: "inherit",
        },
        "& .MuiSelect-select": {
            paddingLeft: 12
        }
    },
    textAlign: {
        paddingLeft: 12,
        "& .MuiTypography-root": {
            paddingLeft: 0
        }
    },
    referenceInputPadding: {
        "& .MuiSelect-select": {
            paddingRight: 24,
            // padding: "27px 12px 10px"
        }
    },
});


const UserList = props => (
    <List
        {...props}
        pagination={<Pagination />}
        perPage={25}
        title={props.options.label}
        actions={<AListActions {...props} />}
        bulkActionButtons={false}
    >
        <Datagrid rowClick="show">
            <TextField label="Имя" source="firstName" />
            <TextField label="Фамилия" source="lastName" />
            <ChipListField label="Роли" source="roles" toValue={val => RolesChoices.find(rc => rc.id === val)?.name} />
            <ChipListField label="Зоны ответственности" source="areas" toValue={val => val.name} />
        </Datagrid>
    </List>
);

const Title = (props) => {
    return (<div>Пользователь "{props.record?.fullName ?? ""}"</div>)
};

const UserShow = ({ ...props }) => (
    <Show {...props} actions={<UserActions />} title={<Title />}>
        <TabbedShowLayout>
            <Tab label="Пользователь">
                <SimpleShowLayout>
                    <TextField label="Имя" source="firstName" />
                    <TextField label="Фамилия" source="lastName" />
                    <TextField label="Email" source="email" />
                    <TextField label="Подпись" source="signature" />
                    <BooleanField label="Заблокирован" source="isBlocked" />
                    <TextField label="Роли" source="role_" />{/* this field need only for label */}
                    <ChipListField label="Роли" source="roles" toValue={val => RolesChoices.find(rc => rc.id === val)?.name} />                    
                </SimpleShowLayout>
            </Tab>
            <Tab label="Зоны ответственности">
                <ReferenceManyField perPage={10} pagination={<Pagination />} reference={entities.areasOfResponsibility} target="ownerId" label="Зоны ответственности">
                    <Datagrid rowClick="show">
                        <TextField label="Имя" source="name" />
                        <RemoveAreaFromUserButton userId={props.id} />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="История сопоставлений">
                <ReferenceManyField perPage={10} pagination={<Pagination />} reference={entities.areasOfResponsibilityUsers} target="userId" label="История сопоставлений">
                    <Datagrid>
                        <ReferenceField link="show" label="Зона ответственности" source="areaOfResponsibilityId" reference={entities.areasOfResponsibility}>
                            <TextField source="name" />
                        </ReferenceField>
                        <DateField label="Начало периода" source="validFrom" />
                        <DateField label="Конец периода" source="validTo" />
                        <ReferenceField link="show" label="Кто назначил" source="issuerId" reference={entities.users}>
                            <TextField source="firstName" />
                        </ReferenceField>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const RemoveAreaFromUserButton = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();    

    const handleOnClick = async (e) => {
        e.stopPropagation();
        const result = await api.removeAreaFromUser(parseInt(props.userId), parseInt(props.record.id));
        const data = await result?.json();
        if (data?.succeeded) {
            notify('КАМ снят с зоны');
            refresh();
        }
        else
            notify(
                `Ошибка: не удалось снять КАМа с зоны.\n
                Причина: ${data ? data.errorMessage : `Статус ответа: ${result?.status}`}`, 'warning'
            );
    };


    return (
        <Button size="small" variant="outlined" color="primary" onClick={handleOnClick}>
            Снять
        </Button>
    );
}



const UserEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton 
            transform={data => {
                const { roles, ...rest } = data;
                let result = rest;
                result.roles = Array.isArray(roles) ? roles[0] : roles;
                if (result.roles !== RolesNumber.Manager)
                    result.managerId = undefined;
                return result;
            }}
        />
    </Toolbar>
);

const SingleSelectRoleInput = (props) => {
    let { label, record, classes, ...restProps } = props;
    let { roles, ...restRecord } = props.record;
    restRecord.roles = roles[0];
    const newProps = restProps.record = restRecord;
    
    return (
        <SelectInput source="roles" choices={RolesChoices} validate={[required()]} {...newProps} className={classes.selectAlign}/>
    );
}

const UserEdit = (props) => {
    const classes = useStyles();
    
    const isManager = (roles) => {
        if(Array.isArray(roles)) {
            return roles[0] === RolesNumber.Manager; 
        }

        if(Number.isInteger(roles)) {
            return roles === RolesNumber.Manager;
        }

        return false;
    }

    const {loading} = useEditController(props);
    console.log(loading)

    return <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<UserEditToolbar />} actions={<ShowButton />} redirect={false} className={classes.addLabel}>
            <TextInput label="Имя" source="firstName" validate={[required()]} />
            <TextInput label="Фамилия" source="lastName" validate={[required()]} />
            <BooleanInput label="Заблокирован" source="isBlocked" className={classes.textAlign} />
            <TextField label="Email" source="email" className={classes.textAlign} />
            <SingleSelectRoleInput label="Роли" classes={classes}/>
            <FormDataConsumer>
                {({ formData }) => {
                    return (
                            isManager(formData.roles) &&
                            <ReferenceInput source="managerId" reference={entities.users} label="Федеральный менеджер" filter={{ onlyFederalManager: true }} className={classes.selectAlign}>
                                <SelectInput optionText="fullName" />
                            </ReferenceInput>
                        );
                    }
                }
            </FormDataConsumer>
            <TextInput label="Подпись" source="signature" multiline maxRows={5} fullWidth/>
        </SimpleForm>
    </Edit>
};

const UserCreateToolbar = props => (
    <Toolbar {...props} >
        <SaveButton 
            transform={data => {
                let result = data;
                if (result.role !== RolesNumber.Manager)
                    result.managerId = undefined;

                return result;
            }}
        />
    </Toolbar>
);

const UserCreate = (props) => {
    const classes = useStyles();
    return <Create {...props}>
        <SimpleForm toolbar={<UserCreateToolbar />} redirect="list" className={classes.addLabel}>
            <TextInput label="Имя" source="firstName" validate={[required()]} />
            <TextInput label="Фамилия" source="lastName" validate={[required()]} />
            <TextInput label="Email" source="email" validate={[required(), email()]}/>
            <SelectInput label="Роль" source="role" choices={RolesChoices} validate={[required()]} className={classes.selectAlign}/>
            <FormDataConsumer>
                {({ formData }) => formData.role === RolesNumber.Manager &&
                    <ReferenceInput source="managerId" reference={entities.users} label="Федеральный менеджер" filter={{ onlyFederalManager: true }} validate={[required()]} className={classes.selectAlign} >
                        <SelectInput optionText="fullName"  />
                    </ReferenceInput>
                }
            </FormDataConsumer>
            <TextInput label="Подпись" source="signature"  multiline maxRows={5} fullWidth/>
        </SimpleForm>
    </Create>
};

const UserActions = (props) => {
    const [id, setId] = React.useState(-1);

    const notify = useNotify();
    const refresh = useRefresh();
    const { permissions } = usePermissions();

    const addAreaToUser = async (userId, areaId) => {
        const result = await api.addAreaToUser(userId, areaId);
        if (result) {
            notify('Зона добавлена');
            refresh();
        }
        else
            notify('Ошибка: не удалось добавить зону', 'warning');
    }

    const sendResetPasswordUrl = async () => {
        const result = await api.sendResetPasswordUrl(props.data.id);
        if (result.succeeded)
            notify('Ссылка отправлена');
        else if (result.errorMessage != null)
            notify(result.errorMessage, 'warning');
        else
            notify('Ошибка: не удалось отправить ссылку', 'warning');
    }

    return (
        <TopToolbar >
            {isAdmin(permissions) && <Button variant="text" color="primary"onClick={sendResetPasswordUrl}>Отправить ссылку для сброса пароля</Button>}
            <MapBulkButtonLayout
                handleClick={async () => await addAreaToUser(props.data.id, id)}
                header="Добавить зону ответственности"
                cancelButtonText="Отмена"
                acceptButtonOptions={{ autoFocus: true }}
                acceptButtonText="Добавить"
                dialogContentText="Выберите зону ответственности"
                dialogTitle=""
                dialogContent={
                    <SimpleForm toolbar={null}>
                        <ReferenceInput onChange={(e) => setId(e.target.value)}
                            label="Зона ответственности" source="areaId" reference={entities.areasOfResponsibility}
                            filter={{ notOwnedByUserId: [props.data?.id] }}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    </SimpleForm>
                }
            />
            <HistoryButton buttonText="История пользователя" dialogTitle="История пользователя" parentEntityKind={1} {...props}/>
            <EditButton basePath={props.basePath} record={props.data} />

        </TopToolbar>
    );
}

export default {
    list: UserList,
    show: UserShow,
    edit: UserEdit,
    create: UserCreate
}