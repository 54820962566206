import React from 'react';
import { AutocompleteInput as AutocompleteInputAdmin } from 'react-admin';
import { makeStyles } from '@material-ui/styles';

const useStyles =  makeStyles((theme) => ({
    root: {
        paddingLeft: '0px'
    }
}))

const AutocompleteInputOrderShow = (props) => {
    const classes = useStyles()
    const { options, placeholder, useLabelAsPlaceholder = false, isNotDefaultColor = false, ...rest } = props;

    const notSelectedValue = rest.input?.value?.length === 0;

    const styles = {}

    if (isNotDefaultColor) {
        styles['style'] = { color: notSelectedValue ? "#aaa" : "#000" }
    }

    const innerInputProps = { inputProps: { ...styles, className: classes.root } }

    const innerOptions = { placeholder: useLabelAsPlaceholder && props.label != null ? props.label : placeholder ?? 'Выбрать', ...options, ...innerInputProps };

    return <AutocompleteInputAdmin options={{...innerOptions}} {...rest} />;
}

export default AutocompleteInputOrderShow;