import React, { useState } from "react";
import {
    Create, required, FormDataConsumer,
    SaveButton, SimpleForm, 
    TextInput, Toolbar, TopToolbar
} from "react-admin";
import { QueryBuilder } from "../../AQB";
import BackTo from '../../components/BackTo';

const GetTokenForQueryBuilder = () => "React";

const ReportCreate = (props) => {
    const [reportName, setReportName] = useState("");
    const [query, setQuery] = useState(null);
    const [clientQuery, setClientQuery] = useState(null)
    const buttonName = "Назад к отчетам";

    const sqlTextHandler = (clientSQL, SQL) => {
        setClientQuery(clientSQL);
        setQuery(SQL);
    }

    const updateHandler = (clientSQL, SQL) => {
        setClientQuery(clientSQL);
        setQuery(SQL);
    }

    const queryProps = {
        reportName,
        query,
        clientQuery,
        buttonName
    }

    return (
        <Create {...props} actions={<ReportCreateTopToolbar {...queryProps} />}>
            <SimpleForm toolbar={<ReportCreateToolbar {...queryProps} />} redirect="list">
                <TextInput label="Название" source="name" validate={[required()]} />
                <QueryBuilder 
                    name={GetTokenForQueryBuilder()}
                    createQB={true}
                    sqlTextHandler={sqlTextHandler} 
                    updateHandler={updateHandler}
                />
                <FormDataConsumer>
                {({ formData, ...rest }) => {
                    setReportName(formData.name);
                    return null;
                }}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
}

const ReportCreateToolbar = props => {
    const { reportName, query, clientQuery, buttonName } = props;
    const isHidden = isAlertHidden(query, clientQuery, reportName);

    return (
        <Toolbar {...props} >
            <SaveButton />
            <BackTo 
                isAlertHidden={isHidden} 
                buttonName={buttonName} 
                url={`/reports`}
            />
        </Toolbar>
    );
};


const ReportCreateTopToolbar = props => {
    const { reportName, query, clientQuery, buttonName } = props;
    const isHidden = isAlertHidden(query, clientQuery, reportName);

    return (
        <TopToolbar {...props} >
            <BackTo 
                isAlertHidden={isHidden}
                buttonName={buttonName} 
                url={`/reports`}
            />
        </TopToolbar>
    );
};

const isAlertHidden = (query, clientQuery, reportName) => 
    !reportName && !query && !clientQuery;

export default ReportCreate;