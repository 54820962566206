import * as React from "react";
import { Datagrid, DateField, ReferenceField, TextField } from "react-admin";
import useAdditionalFilterValues from "../../common/useAdditionalFilterValues";
import Pagination from "../../components/DefaultPagination";
import Filter from '../../components/GphcUIKit/Filter';
import List from "../../components/List";
import { AListActions } from '../../components/ListActions';
import PercentField from "../../components/PercentField";
import { entities } from "../../configuration";
import api from "../../dataProvider/api";
import { FactoryComponents } from '../../common/enums';

const MaxWholesaleMarkupsList = (props) => {
    const filters = useAdditionalFilterValues(api.getMaxWholesaleMarkupFilterValues);

    return <List {...props}
        filters={
            <Filter
                searchComponentOptions={
                    [
                        { type: FactoryComponents.Search, source: "q", alwaysOn: true }
                    ]
                }
                dateComponentsOptions={
                    [
                        { type: FactoryComponents.Date, source: "dateActualTo", label: "Актуальность на", alwaysOn: true },
                    ]
                }
                filtersComponentsOptions={[{ type: FactoryComponents.Region, alwaysOn: true, perPage: 300 }, ...filters]}
                {...props}
            />
        }
        filter={{ history: false }}
        pagination={<Pagination />}
        perPage={25}
        title={props.options.label}
        actions={<AListActions {...props} />}
        bulkActionButtons={false}
    >
        <Datagrid rowClick="" >
            <ReferenceField source="regionId" label="Регион" reference={entities.regions} link={false}>
                <TextField source="name" />
            </ReferenceField>
            <PercentField source="lessOrEqual50MaxMarkup" label=" n <= 50 руб. " />
            <PercentField source="between50And500MaxMarkup" label=" 50 > n <= 500 " />
            <PercentField source="greater500MaxMarkup" label=" n > 500 руб. " />
            <TextField source="comment" label="Комментарии" />
            <DateField source="validFrom" label="Дата загрузки" />
        </Datagrid>
    </List>
};

export default {
    list: MaxWholesaleMarkupsList
};