export interface FiltersHeightState {
  purchaseOrders: number,
  purchaseOrdersApproval: number,
  commercialOffers: number,
  skuPriceAdditionalContracts: number,
  skuPrices: number,
  skus: number,
  logisticsPrice: number,
  nomenclatures: number,
  regions: number,
  customers: number,
  skuRegionsRelevance: number,
  skuCustomersRelevance: number,
  maxWholesaleMarkups: number,
  stockBalance: number,
  skuProducers: number,
  suppliers: number,
  persistentLog: number,
  dictionaryImport: number
  inns: number
}

export const CHANGE_FILTERS_HEIGHT_STATE = '@filtersHeight/CHANGE_FILTERS_HEIGHT_STATE';

interface ChangeFiltersHeightState {
  type: typeof CHANGE_FILTERS_HEIGHT_STATE;
  payload: { key: string, height: number };
}

export type FiltersHeightActionsTypes =
| ChangeFiltersHeightState;