import * as React from 'react';
import SuccessFailureIcon from './SuccessFailureIcon';
import { makeStyles } from '@material-ui/core';
import { useCommercialOfferStyles } from '../../entities/orders/styles';

const RelevanceField = (props) => {
    const { label, record, source } = props;
    const classes = useCommercialOfferStyles();

    if (record == null || source == null)
        return null;

    const isSuccess = record[source] === true;
    const text = isSuccess ? "Актуально" : "Не актуально";

    return <>
        <div className={classes.LotRelevanceWrap}>
            <span>{label}</span>
            <div className={classes.LotRelevanceStatus}>
                <SuccessFailureIcon success={isSuccess} />
                <span>{text}</span>
            </div>
        </div>
    </>;
};


export default RelevanceField;
