import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { 
    InputLabel, MenuItem, FormControl, Select, 
} from '@material-ui/core';
import { Version } from "./dtoInterfaces";


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const VersionSelector = ({currentVersion, setCurrentVersion, versions} : {currentVersion: Version, setCurrentVersion: any, versions: Array<Version>}) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Версия</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentVersion.id}    
                onChange={e => setCurrentVersion(e.target.value)}
            >
                {versions.map((element, i) => {
                    return (
                        <MenuItem value={element.id} key={i}>
                            {new Date(element.dateCreate).toLocaleString("ru-RU") + " " + element.authorFullName}
                        </MenuItem>                    
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default VersionSelector;