import * as React from "react";
import { Editor } from '@tinymce/tinymce-react';
import tinymce from 'tinymce/tinymce';

export default (props) => {
    (window as any).tinymce = tinymce;

    const { onEditorChange, value } = props;
    return <Editor
        init={{
            base_url: '/tinymce',
            language: 'ru',
            height: 500,
            menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help'
            ],
            toolbar:
                'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help'
        }}
        value={value}
        {...props}
        onEditorChange={onEditorChange}
    />;
}