import React from 'react';
import { SelectInput as SelectInputAdmin } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import { createOnChangeWrapper } from '../../common/helpers';

const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
      color: "#aaa"
    }
  }));
  
const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};

const SelectInputSingle = (props) => {
    const { source, choices, optionValue = "id", optionText = "name", placeholder = "Выбрать", label, excludedValue, emptyChoicesItemText } = props;
    const { options, onChange, useLabelAsPlaceholder = false, isNotDefaultColor = false, ...rest } = props;

    const getPlaceholder = () => {
        if (excludedValue != null)
            return excludedValue[optionText];

        return useLabelAsPlaceholder && label != null ? label : placeholder;
    }

    const renderValue = (value) => {
        if (value == null || value === '')
            return isNotDefaultColor ? <Placeholder>{getPlaceholder()}</Placeholder> : getPlaceholder()

        if (choices == null || choices.length == 0)
            return isNotDefaultColor ? <Placeholder>{getPlaceholder()}</Placeholder> : getPlaceholder()

        const picked = choices.find(i => i[optionValue] == value);
        if (picked == null)
            return isNotDefaultColor ? <Placeholder>{getPlaceholder()}</Placeholder> : getPlaceholder()

        if (typeof optionText == 'string')
            return picked[optionText];
        else if (typeof optionText == 'function')
            return optionText(picked);

        return value;
    }

    const getInnerProps = () => {
        if (excludedValue == null)
            return rest;

        const { record, ...innerRest } = rest;

        if (choices != null && choices.length == 0 && emptyChoicesItemText != null) {
            const item = { disabled: true };
            item[optionText] = emptyChoicesItemText;
            choices.push(item)
        }

        return innerRest;
    }

    return <SelectInputAdmin options={{ SelectProps: { renderValue, displayEmpty: true }}} onChange={createOnChangeWrapper(props)} {...getInnerProps()} />;
}


export default SelectInputSingle;