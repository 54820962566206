export interface Togglers {
  purchaseOrders: boolean,
  purchaseOrdersApproval: boolean,
  commercialOffers: boolean,
  skuPriceAdditionalContracts: boolean,
  skuPrices: boolean,
  skus: boolean,
  logisticsPrice: boolean,
  nomenclatures: boolean,
  regions: boolean,
  customers: boolean,
  skuRegionsRelevance: boolean,
  skuCustomersRelevance: boolean,
  maxWholesaleMarkups: boolean,
  stockBalance: boolean,
  skuProducers: boolean,
  suppliers: boolean,
  persistentLog: boolean,
  dictionaryImport: boolean
  inns: boolean
}

export interface TogglerState {
  isToggled: Togglers
}

export const CHANGE_TOGGLER_STATE = '@toggler/CHANGE_TOGGLER_STATE';

interface ChangeTogglerPurchaseOrders {
  type: typeof CHANGE_TOGGLER_STATE;
  payload: string;
}

export type TogglerActionsTypes =
| ChangeTogglerPurchaseOrders;