import * as React from 'react';
import { useState, useCallback } from 'react';
import { useGetList, LinearProgress, Error } from 'react-admin';

const ReferenceInput = (props) => {
    const { 
        reference,
        source,
        pagination,
        sort,
        filter,
        perPage,
        children,
        onChange,
        excludedValue,
        record,
        disabled,
        options,
    } = props;

    const [ filterString, setFilterString ] = useState("");
    const innerFilter = {...filter};
    if (filterString != "")
        innerFilter.q = filterString;

    const { loaded, error, data } = useGetList(reference, pagination || {page: 0, perPage: perPage || 300}, sort || {}, filter || {});

    const setFilter = useCallback((value) => {
        setFilterString(value); //todo: add throttle
    }, [setFilterString]);
    
    if (!loaded)
        return <LinearProgress />;

    if (error) 
        return <Error />;

    const items: any[] = [];    
    for (let id in data) {
        items.push(data[id]);
    }

    const picked = record == null || source == null ? null : items.find(c => c.id == record[source]);
    const isExcluded = typeof excludedValue == 'function' && excludedValue(picked);
    const excludedInnerValue = isExcluded ? picked : null;

    const choices: any[] = items.filter(i => typeof excludedValue != 'function' || !excludedValue(i));

    return React.cloneElement(children, { excludedValue: excludedInnerValue, onChange, source, choices, setFilter, disabled, options });
};

ReferenceInput.defaultProps = {
    //addLabel: true,
};

export default ReferenceInput;
