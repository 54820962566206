import React from 'react';

export function canApplyImport(isImporting: boolean, responseState: any) {

    const getTotalErrorCount = (state) => state.reduce((acc, s) => acc + getErrorCount(s.writeActionStats), 0);
    const getErrorCount = (actionStats) => actionStats.reduce((inAcc, a) => inAcc + a.errors, 0);

    return isImporting === false && responseState != null && getTotalErrorCount(responseState) == 0

}

export function getErrorCount(actionStats) { 
    return actionStats.reduce((inAcc, a) => inAcc + a.errors, 0); 
}
