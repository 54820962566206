import React from 'react';
import { useState } from 'react';
import { Button, ClickAwayListener, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {useNotify, useRefresh} from 'react-admin';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import classnames from 'classnames';
import CurrencyField from './CurrencyField';

const useStyles = makeStyles((theme: any) => ({
    textField: {
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
        },
        "& input": {
            padding: "10px 14px"
        }
    },
    backdrop: {
        "& input": {
            color: "#969696",
            background: "rgb(0 0 0 / 17%)",
        }
    },
    dialogButton: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        background: "#ffffff",
        width: 104,
        boxShadow: "0px 12px 14px rgba(0, 0, 0, 0.08)",
        borderRadius: 4,
        padding: '15px 16px 12px',
        lineHeight: 1,
        zIndex: 1,
        transform: 'translate(0px,-52px)',
        "& .save": {
            background: "#009BC3",
            borderRadius: 6,
            marginBottom: 5,
            color: "#fff",
            height: 24
        },
        "& .cancel": {
            background: "rgba(0, 155, 195, 0.12)",
            borderRadius: 6,
            color: "#009BC3",
            height: 24
        },
        "& input":{
            textDecoration: "blink !important",
            padding: "0 !important"
        },
        "& hr":{
            height: 2,
            background: "#009BC3",
            marginBottom: 14,
            marginLeft:0,
        },
        
        '& .MuiOutlinedInput-adornedStart':{
            padding: 0
        },
        '& .MuiFormControl-root': {
            paddingBottom: 7,
            
        },
        '& .MuiButton-label': {
            textTransform: 'none',
        },
        '& .MuiButton-text': {
            padding: 3
        }
    },
}));

type PropTypes = {
    record: any,
    value?: any,
    disabled: boolean,
    classes?: any,
    placeholder: string,
    source: any,
    openEditByDefault?: boolean,
    decimalPlaces: number,
    onCancel?: () => void,
    onSave?: (value: number) => void,
    onBlurHandler: (api: (apiFunc: any, textNotify: string, errorNotify: string) => Promise<boolean | undefined>, value: number, props: PropTypes) => void,
};

export default (props: PropTypes) => {
    const propsValue = props.value ?? (props.record != null ? props.record[props.source] : 0);
    const [value, setValue] = useState(propsValue != null ? Number.parseFloat(propsValue) : 0);
    const [isError, setIsError] = useState(false);
    const [focus, setFocus] = useState(props.openEditByDefault || false);
    const refresh = useRefresh();
    const notify = useNotify();
    const decimalPlacesState = props.decimalPlaces ?? 2;

    const classes = useStyles();
    
    if (props.disabled) {        
        const { record, ...rest } = props;

        const innerRecord = {...record};
        if(!innerRecord[props.source])
            innerRecord[props.source] = 0;

        return <CurrencyField {...{...rest, record: innerRecord }} />;
    }        

    const setValueToApi = async (apiFunc, textNotify, errorNotify) => {
        try{
            const response = await apiFunc();
            if(response.ok) {
                const result = await response?.json();
                if (result?.succeeded) {
                    props.record[props.source] = value;                    
                    let grid = document.getElementsByClassName("overflow-left-grid")[0];
                    //@ts-ignore
                    let scroll = grid.scrollLeft;
                    refresh();
                    notify(textNotify);
                    //@ts-ignore
                    let newGrid = document.getElementsByClassName("overflow-left-grid")[0];
                    newGrid.scrollLeft = scroll;
                    return true;
                }
            }
            notify(errorNotify ?? "Непредвиденная ошибка", 'warning');
            return false;
        }
        catch(e){
            notify(errorNotify ?? "Непредвиденная ошибка, 'warning' ");
        }
    }

    const onChangeHandler = (e, v) => {
        if (v < 0) {
            setIsError(true);
            setValue(v);
            return;
        }
        setValue(v);
        setIsError(false);
    }

    const handler = async (e) => {
        if (value < 0 || value === Number.parseFloat(props.record[props.source]))
            return;
        await props.onBlurHandler(setValueToApi, value, props);
    }

    const onKeyDown = async e => {
        if(e.keyCode === 13){
            await handler(e);
        }
    }

    const onBlurHandler = async e => {
        await handler(e);
    }

    if (props.record == null)
        return null;    

    return (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => {
            setFocus(false);
            setValue(propsValue)
        }}>
        <form>
            <CurrencyTextField
                error={isError}
                variant="outlined"
                value={value}
                onFocus={e => setFocus(true)}
                onKeyDown={onKeyDown}
                onChange={onChangeHandler}
                currencySymbol=""
                digitGroupSeparator=" "
                maximumValue={9999999}
                minimumValue={0}
                decimalCharacter=","
                decimalPlaces={decimalPlacesState}
                outputFormat="number"
                classes={props.classes || {root: classes.textField}}
                InputLabelProps={{ shrink: true }}
                helperText={isError ? "Число должно быть больше 0" : ""}
                placeholder={props.placeholder}
            />
            {focus &&
                <div className={classes.dialogButton}>
                    <CurrencyTextField
                        error={isError}
                        variant="outlined"
                        value={value}
                        onFocus={e => setFocus(true)}
                        onKeyDown={onKeyDown}
                        onChange={onChangeHandler}
                        currencySymbol=""
                        decimalPlaces={decimalPlacesState}
                        digitGroupSeparator=" "
                        maximumValue={9999999}
                        minimumValue={0}
                        decimalCharacter=","
                        outputFormat="number"
                        classes={classnames(props.classes || {root: classes.textField}, ) }
                        InputLabelProps={{ shrink: true }}
                        helperText={isError ? "Число должно быть больше 0" : ""}
                        placeholder={props.placeholder}
                    />
                    <Divider variant="inset" />
                    <Button
                        onClick={e => {
                            setFocus(false);
                            if (props.onSave !== undefined) {
                                props.onSave(value);
                            } else {
                                onBlurHandler(e);
                            }
                        }}
                        className="save"
                    >
                        Сохранить
                    </Button>
                    <Button onClick={e => {
                        setFocus(false);
                        setValue(propsValue);
                        if (props.onCancel !== undefined) {
                            props.onCancel();
                        } else {
                            onBlurHandler(e);
                        }
                    }} className="cancel">
                        Отменить
                    </Button>
                </div>
            }
        </form>
        </ClickAwayListener>
    );
}