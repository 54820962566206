import 'date-fns';
import React, { useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
// redux connect
import { connect } from 'react-redux';
import { PUSH_BUBBLE_OBJECT } from '../../store/modules/bubbles/types';
// styles
import { ThemeProvider } from "@material-ui/styles";

import customTheme from '../../customTheme/customTheme';

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    '&': { 
      width: '200px', 
      marginTop: '8px',
    },
    // такой вот способ добраться до иконки
    '& div div button': {
      padding: 0
    }
  },
});

const DateInputMaterialKeyboard = (props) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    null,
  );

  // redux state
  const { resources } = props;
  // current router with finding local state in redux admin
  const { pathname } = props;
  const localResources = resources[pathname];
  // make object without reference in memory to make work setFilters on other pages
  const filters = { ...localResources?.list?.params?.filter };

  // input name
  const { source } = props;
  // setFilter method
  const { setFilters } = props;
  // get label
  const { label } = props;
  // The first commit of Material-UI
  const { addChoicesToBubbles } = props;

  function parseDate(dateString) {
    let dateObject = new Date(dateString);
    setSelectedDate(dateObject)
  }

  useEffect(() => {
    // необходимо явно указать, что этот инпут - не селект
    // тогда в FilterBubbles будет понятно, что надо вернуть строку
    // { tenderDateFrom: '19.03.2020' }
    // в таком виде хранятся объекты в редакс фильтрах
    // если мы выбираем селект, тогда 
    // { source: 0 }
    // здесь 0 - будет использован как индекс для отображения в массиве choices
    // let choices = ['выбор1', 'выбор2']
    // choices[0]
    addChoicesToBubbles({
      key: pathname,
      choices: { source: source, label: label, choices: [] }
    })
  }, [])

  // watch for filters date
  useEffect(() => {
    filters[source] ? parseDate(filters[source]) : setSelectedDate(null)
  }, [filters[source]])

  if (filters == null)
    return null;  

  function handleDateChange (date: Date | null) {
    // event for cleared input
    if (date === null) {
      filters[source] = '';
      setFilters(filters);
    }
    // set selected date
    setSelectedDate(date);
    // validate date and update filters
    if (date && date.toString() !== 'Invalid Date') {
      filters[source] = date;
      setFilters(filters);
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <KeyboardDatePicker
            variant="inline"
            format="dd.MM.yyyy"
            margin="normal"
            id="date-picker-inline"
            label={label}
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            minDateMessage="Минимальная дата 01.01.1900"
            maxDateMessage="Максимальная дата 01.01.2100"
            invalidDateMessage="Введите дату в формате дд.мм.гггг"
            className={classes.root}
          />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

function mapStateToProps(state) {
  const { admin, router } = state;
  // pathname === '/myPath' --> 'myPath'
  return { resources: admin.resources, pathname: router.location.pathname.split('/')[1] }
}

function mapDispatchToProps(dispatch) {
  return {
      addChoicesToBubbles: (payload) => dispatch({ type: PUSH_BUBBLE_OBJECT, payload: payload }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateInputMaterialKeyboard)