import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useQuery } from 'react-admin';
import { NameDictionaryImport } from '../../common/helpers';
import { entities } from '../../configuration';

export const ImportProtocol = (props) => {

    const useStyles = makeStyles({
        head: {
            "& .MuiTableRow-head": {
                
                background: '#F7F7F8'
            },
            "& .MuiTableCell-head":{
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '100%',
                color: '#646469'
            }
        }
    });

    const { record } = props;

    const classes = useStyles();

    const { data, loading, error } = useQuery({ 
        type: 'getOne',
        resource: entities.xlsConfigs,
        payload: { id: props.record.importConfigId }
    });

    if (loading)
        return <CircularProgress />;

    if (data?.name === NameDictionaryImport)
        return <div></div>;

    function countValue(actions: any[], actionName: string) {
        let number = 0;
        for (let key in actions) {
            let action = actions[key];
            if (action.localizedActionName == actionName && action.errors == 0)
                number += action.count;

            else if (action.localizedActionName == actionName && action.errors != 0)
                return "Есть ошибки"
        }

        return number.toString();
    }

    const getRecord = (record: any) => {

        const values: { name: string, entityName: string, updated: string, added: string }[] = [];

        let elements: any = JSON.parse(record.processingResult)

        if (elements[0].entityName == null)
            return

        elements.forEach(item => {
            let value = {
                name: item.entityName, 
                entityName: item.localizedEntityName, 
                updated: countValue(item.writeActionStats, "обновление"), 
                added: countValue(item.writeActionStats, "вставка")}
            values.push(value);
        });

        return values;
    }

    function createData() {
        return getRecord(record)
    }

    const rows = createData();

    if (rows == null) {
        return <div></div>
    }

    return (
        <Table>
            <TableHead className={classes.head}>
                <TableRow>
                    <TableCell>Название</TableCell>
                    <TableCell>Обновлено</TableCell>
                    <TableCell>Добавлено</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <TableRow key={row.name}>
                        <TableCell >{row.entityName}</TableCell>
                        <TableCell >{row.updated}</TableCell>
                        <TableCell >{row.added}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

