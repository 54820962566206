import * as React from "react";
import { useEffect } from "react";
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
    ReferenceInput, ReferenceField, Identifier,
    SimpleForm, SimpleShowLayout, DateField, SelectField, ReduxState, toggleListItemExpand,
    useNotify, useRefresh, NumberField, usePermissions
} from "react-admin";
import CurrencyField from "../../../components/CurrencyField";
import PercentField from "../../../components/PercentField";
import { entities } from "../../../configuration";
import {
    PurchaseOrderResultStatusChoices,
    PurchaseOrderProcessingStatusChoices,
    PurchaseOrderLotTypeChoices,
    PurchaseOrderProcessingManagerStatusChoices,
} from "../../../common/choices";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from "../../../components/WrapperTooltip";
import Texts from "../../../common/tooltipCalculatedTexts";
import api from "../../../dataProvider/api";
import EditableTextField from "../../../components/EditableTextField";
import { CommercialOfferType } from "../../../common/enums";
import OrderRelevance from "../../../components/GphcUIKit/OrderRelevance";
import TextField from "../../../components/GphcUIKit/TextField";
import AutocompleteInputSingle from "../../../components/GphcUIKit/AutocompleteInputSingle";
import SelectInputSingle from "../../../components/GphcUIKit/SelectInputSingle";
import { CommercialOfferStatus, CommercialOfferTotalStatus } from '../../../components/CommercialOfferStatus';
import { useSelector, useDispatch } from 'react-redux';
import { createOnChangeWrapper, isAdmin, isObserver } from "../../../common/helpers";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'transparent',
        position: "relative",
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: 300,
        '& > div': {
            width: "100%",
            paddingLeft: 16
        },
    },
    headerList: {
        color: "rgba(0, 0, 0, 0.54);",
        fontSize: "1.1em",
        background: "rgb(245 245 245)",
    },
    linkOrderNumber: {
        height: 59,
        width: "100%"
    },
    purchaseOrderItem: {
        '& .ra-field': {
            borderBottom: "#e0e0e0 1px solid",
            minHeight: 61
        }
    },
    textRoot: {
        padding: '0 16px',
        '& label': {
            marginLeft: 16
        },
        width: "91%"
    },
    agreementStatusChoices: {
        '& div': {
            color: "#000"
        }
    },


    customPaperWrap: {
        width: '100%'
    },

    wrapper: {
        maxWidth: "inherit"
    },

    customPaper: {
        padding: '25px 0',
        backgroundColor: '#FCFCFC',
        borderRadius: 12,
        justifyContent: "flex-start",

        '&.MuiGrid-container': {
            // maxWidth: 1200
        }
    },

    orderInfoItem: {
        width: '100%',
        maxWidth: '400px',
        borderRight: '1px solid #E9E9ED',
        padding: '0 36px',

        '&:first-child': {
            paddingLeft: '20px',
            // maxWidth: '320px',
            '& .ra-input > div > span': {
                minWidth: '133px',
            },
        },

        '&:nth-child(2)': {
            // maxWidth: '320px',
            paddingLeft: '20px',
            '& .ra-input > div > span': {
                minWidth: '150px',
            },
        },

        '&:last-child': {
            maxWidth: '438px',
            border: 'none',

            alignItems: "baseline",
            '& .MuiFormControl-root': {
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 20,


                '& .MuiFormLabel-root': {
                    minWidth: 193,
                    marginRight: 16,
                    transform: 'none',

                    '& span': {
                        fontSize: 14,
                    }
                },

                '& .MuiFormLabel-root + div': {
                    padding: 0
                }
            },
            '& .ra-field > div': {
                display: "flex",
                alignItems: "baseline"
            },
            '& .ra-field > div > span': {
                minWidth: '210px',
            },
            '& .ra-field > div span': {
                whiteSpace: "nowrap"
            },
        },

        '& .MuiCardContent-root': {
            padding: 0,

        }
    },

    orderDateField: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 16,
        lineHeight: '16px',
        margin: 0,
        marginBottom: 14,

        '& .MuiFormLabel-root': {
            minWidth: '133px',
            fontSize: 16,
            lineHeight: '16px',
            color: '#7B7F86',
            transform: 'none',
            marginRight: 16
        },
        '& .MuiFormLabel-root + div': {
            minWidth: '133px',
            fontSize: 16,
            lineHeight: '16px',
            padding: 0,
        },
        '& .RaLabeled-value': {
            padding: 0,
            fontSize: 14,
            lineHeight: '16px',
        },

        // RaLabeled: {
        //     value: {
        //         fontSize: 14,
        //         lineHeight: '16px',
        //     }
        // }

    },

    orderSelect: {
        display: 'flex',
        fontSize: 16,
        lineHeight: '16px',
        fontFamily: 'Clarika Office Geometric',
        alignItems: 'center',
        marginBottom: 14,

        '& > span': {
            width: '133px',
            marginRight: 16,
            color: '#7B7F86',
        },

        '& .MuiFormHelperText-contained': {
            display: 'none',
        },

        '& .MuiFormControl-root': {
            margin: 0,
            minWidth: 0,
            width: '100%'
        },

        '& .MuiSelect-root': {
            padding: 0,
            textDecoration: 'underline',
            color: '#009BC3'

        },

        '& .MuiSelect-iconFilled': {
            display: 'none'
        },

        '& .MuiInputBase-root:before': {
            display: 'none'
        },
        '& .MuiInputBase-root:after': {
            display: 'none'
        },
        // second col inputs
        '& .MuiFilledInput-inputMarginDense': {
            padding: 0
        },
        '& .MuiInputBase-input': {
            textDecoration: 'underline',
            color: '#009BC3',
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: 'gray',
        },
        '& .MuiFilledInput-root.Mui-disabled': {
            backgroundColor: 'initial'
        },
        '[type="text"] & .MuiInputBase-input::-webkit-input-placeholder': {
            opacity: '1 !important'
        }
    },

    orderBtn: {
        '& .MuiButton-outlined': {
            border: 'none',
            color: 'white',
            fontSize: '14px',
            lineHeight: '24px',
            background: '#009BC3',
            borderRadius: '6px',
            padding: '0 16px 0 20px',
            textTransform: 'none'
        }
    },

    orderAccorion: {
        border: 'none',

        '& .MuiAccordionSummary-root': {
            padding: 0,
            maxWidth: 270,
            marginBottom: 10,
        },
        '& .MuiTypography-body1': {
            fontSize: '16px',
            lineHeight: '24px',
        },
        '& .MuiSvgIcon-root': {
            color: '#009BC3'
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    orderAccorionDetails: {
        padding: 0
    },
    orderComment: {
        marginBottom: 20,
        '& .MuiFilledInput-root.Mui-disabled': {
            backgroundColor: '#ffffff'
        },

        '& label': {
            display: 'block'
        },

        '& .MuiFilledInput-input': {
            padding: '21px 12px 10px 0px',
        },

        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'none'
        }
    },
    orderInnerItem: {
        width: '100%',
        maxWidth: '350px',
        borderRight: '1px solid #E9E9ED',
        padding: '0 27px',

        '&:first-child': {
            paddingLeft: '20px',

        },

        '&:last-child': {
            border: 'none'
        },

        '& .MuiCardContent-root': {
            padding: 0,

        },
        '& .MuiFormControl-root': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: 0,
            marginBottom: 12,

        },
        '& .MuiFormControl-root label': {
            color: '#7B7F86',
            marginRight: 12
        },
        '& .MuiFormControl-root label + div': {
            width: 'auto',
            padding: 0,
        },
        '& .MuiTypography-subtitle1': {
            fontSize: '14px',
            lineHeight: '16px',
            marginBottom: 12,
            color: '#7B7F86 !important',
            marginRight: 12
        },
        '& .MuiFormLabel-root': {
            fontSize: '14px',
            lineHeight: '16px',
        },
        '& .MuiInputLabel-shrink': {
            transform: 'none'
        },
    },
}));

const setValueToApi = async (e, apiFunc, id, uiUpdate) => {
    let result = await apiFunc(id, e);
    uiUpdate(result);
}

const StyledReferenceInputManager = (props) => {
    const classes = useStyles();
    const { onChange, ...rest } = props;

    return (
        <div className={classes.orderSelect}>
            <span> {props.label} </span>
            <ReferenceInput onChange={createOnChangeWrapper(props)} {...rest} />
        </div>
    )
}


const StyledReferenceInput = (props) => {
    const classes = useStyles();
    const { onChange, ...rest } = props;

    return (
        <div className={classes.orderSelect}>
            <span> {props.label} </span>
            <ReferenceInput 
                onChange={createOnChangeWrapper(props)}
                options={{ InputProps: { disabled: props.disabled } }}
                {...rest}
            />
        </div>
    );
}

const StyledOrderRelevance = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.orderSelect}>
            <span> {props.label} </span>
            <OrderRelevance {...props} />
        </div>
    );
}

const StyledSelectInputOrderShow = (props) => {
    const classes = useStyles();
    const { label, ...rest } = props;
    return (
        <div className={classes.orderSelect}>
            <span> {label} </span>
            <SelectInputSingle {...rest} />
        </div>
    );
}

const StyledLimitByParticipant = (props) => {
    const classes1 = useStyles();
    return (
        <div className={classes1.orderSelect}>
            <span> {props.label} </span>
            <LimitByParticipant {...props} />
        </div>
    );
}

const StyledCommercialOfferStatus = (props) => {
    const classes = useStyles();
    const { label, ...rest } = props;
    return <div className={classes.orderSelect}>
        <span> {label} </span>
        <CommercialOfferStatus {...rest} />
    </div>;
}

const StyledCommercialOfferTotalStatus = (props) => {
    const classes = useStyles();
    const { label, ...rest } = props;
    return <div className={classes.orderSelect}>
        <span> {label} </span>
        <CommercialOfferTotalStatus {...rest} />
    </div>;
}

const StyledEditableTextField = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.orderComment}>
            <EditableTextField {...props} />
        </div>
    );
}

const OrderPart = (props) => {
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();

    const uiUpdate = (successNotify, rejectNotify) => async (result) => {
        let data = await result.json();
        if (data?.succeeded)
            notify(successNotify);
        else
            notify(`${rejectNotify}\n Причина: ${data.errorMessage}`, 'warning');

        refresh();
    }

    const setPurchaseOrderInitialStatus = async (e) => {
        await setValueToApi(
            e.target.value,
            api.setPurchaseOrderInitialStatus,
            props.record.id,
            uiUpdate("Статус аукциона MEMO обновлен", "Ошибка: не удалось установить Статус аукциона MEMO")
        );
    }

    const setPurchaseOrderManagerInitialStatus = async (e) => {
        await setValueToApi(
            e.target.value,
            api.setPurchaseOrderManagerInitialStatus,
            props.record.id,
            uiUpdate("Статус аукциона КАМ обновлен", "Ошибка: не удалось установить Статус аукциона КАМ")
        );
    }

    const setPurchaseOrderFinalStatus = async (e) => {
        await setValueToApi(
            e.target.value,
            api.setPurchaseOrderFinalStatus,
            props.record.id,
            uiUpdate("Статус аукциона обновлен", "Ошибка: не удалось установить Статус аукциона")
        );
    }

    const setFirstParticipant = async (e) => {
        await setValueToApi(
            e,
            api.setFirstParticipant,
            props.record.id,
            uiUpdate("Участник 1 обновлен", "Ошибка: не удалось установить Участника 1")
        );
    }

    const setSecondParticipant = async (e) => {
        await setValueToApi(
            e,
            api.setSecondParticipant,
            props.record.id,
            uiUpdate("Участник 2 обновлен", "Ошибка: не удалось установить Участника 2.")
        );
    }

    const setParticipantDistributorLogistician = async (e) => {
        await setValueToApi(
            e,
            api.setParticipantDistributorLogistician,
            props.record.id,
            uiUpdate("Дистрибьютор обновлен", "Ошибка: не удалось установить Дистрибьютора")
        );
    }


    const setParticipantWinner = async (e) => {
        await setValueToApi(
            e,
            api.setParticipantWinner,
            props.record.id,
            uiUpdate("Победитель обновлен", "Ошибка: не удалось установить Победителя")
        );
    }

    const setManager = async (e) => {
        await setValueToApi(
            e.target.value,
            api.setManager,
            props.record.id,
            uiUpdate("КАМ обновлен", "Ошибка: не удалось установить КАМ")
        );
    }


    const setComments = async (valuSetter, value, p) => {
        await valuSetter(
            () => api.setComments(p.record.id, value),
            "Комментарий обновлен"
        );
    }

    useEffect(() => {
        collapseAllLots();
    }, [])

    const collapseAllLots = () =>
        expandedList.forEach(element => {
            dispatch(toggleListItemExpand('lots', element));
        });

    const dispatch = useDispatch();
    const expandedList = useSelector<ReduxState, Identifier[]>(
        (reduxState: ReduxState) =>
            reduxState.admin.resources['lots']
                ? reduxState.admin.resources['lots'].list.expanded
                : undefined);

    const { record: { isFirstCommercialOfferOnApproval, isSecondCommercialOfferOnApproval,
        isCommercialOfferOnApproval } } = props;
    const { permissions } = usePermissions();

    const isReadonly = isObserver(permissions);

    return (
        <Grid spacing={0} className={classes.wrapper} container>
            <Grid item className={classes.customPaperWrap} >
                <Grid className={classes.customPaper} container>
                    <Grid item className={classes.orderInfoItem}>
                        <SimpleForm {...props} toolbar={null}>
                            <StyledOrderRelevance label="Актуальность" />
                            <DateField className={classes.orderDateField} label="Окончание подачи" source="filingDateEnd" />
                            <DateField className={classes.orderDateField} label="Дата проведения" source="tenderDate" />
                            <StyledReferenceInputManager
                                source="managerId"
                                label="КАМ"
                                reference={entities.users}
                                onChange={setManager}
                                filter={{canOwnOrder: true}}
                                disabled={isReadonly || isCommercialOfferOnApproval}
                            >
                                <SelectInputSingle optionText={choice => `${choice.firstName} ${choice.lastName}`} />
                            </StyledReferenceInputManager>

                            <StyledSelectInputOrderShow 
                                disabled={false} 
                                source="purchaseOrderManagerStatus" 
                                choices={PurchaseOrderProcessingManagerStatusChoices} 
                                label="Статус аукциона КАМ" 
                                onChange={setPurchaseOrderManagerInitialStatus} 
                                allowEmpty
                            />

                            <StyledSelectInputOrderShow disabled={true} source="processingStatus" choices={PurchaseOrderProcessingStatusChoices} label="Статус аукциона МЕМО" onChange={setPurchaseOrderInitialStatus} />
                            <ResultStatusField disabled={isReadonly} source="resultStatus" label="Результат" onChange={setPurchaseOrderFinalStatus} permissions={permissions}/>
                        </SimpleForm>
                    </Grid>
                    <Grid item className={classes.orderInfoItem}>
                        <SimpleForm {...props} toolbar={null}>
                            <StyledLimitByParticipant
                                label="Участник 1"
                                source="firstParticipantId"
                                onChange={setFirstParticipant}
                                participantSource="firstParticipantId"
                                disabled={isReadonly || isFirstCommercialOfferOnApproval}
                            />
                            <StyledLimitByParticipant
                                label="Участник 2"
                                source="secondParticipantId"
                                onChange={setSecondParticipant}
                                participantSource="secondParticipantId"
                                disabled={isReadonly || isSecondCommercialOfferOnApproval}
                            />
                            <StyledReferenceInput
                                source="participantDistributorLogisticianId"
                                label="Участник-логист"
                                reference={entities.suppliers}
                                onChange={setParticipantDistributorLogistician}
                                filter={{ isSpecial: true }}
                                disabled={isReadonly}
                                allowEmpty
                            >
                                <AutocompleteInputSingle source="name" />
                            </StyledReferenceInput>
                            <StyledReferenceInput
                                source="participantWinnerId"
                                label="Участник-победитель"
                                reference={entities.suppliers}
                                onChange={setParticipantWinner}
                                disabled={isReadonly}
                                allowEmpty
                            >
                                <AutocompleteInputSingle source="name" />
                            </StyledReferenceInput>
                            <StyledEditableTextField
                                {...props}
                                variant="filled"
                                source="comments"
                                hasLabel
                                label="Комментарий"
                                fullWidth
                                onBlurHandler={setComments}
                                multiline={true}
                                rows={4}
                                disabled={isReadonly}
                            />
                        </SimpleForm>
                    </Grid>
                    <Grid item className={classes.orderInfoItem}>
                        <SimpleShowLayout {...props}>
                            <StyledCommercialOfferStatus collapseAllLots={collapseAllLots} source="firstCommercialOfferApprovalStatus" label="Статус согласования КП1" offerType={CommercialOfferType.First} />
                            <StyledCommercialOfferStatus collapseAllLots={collapseAllLots} source="secondCommercialOfferApprovalStatus" label="Статус согласования КП2" offerType={CommercialOfferType.Second} />
                            <StyledCommercialOfferTotalStatus collapseAllLots={collapseAllLots} source="commercialOfferApprovalCommonStatus" label="Общий статус согласования" />
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <AdditionalInfoAccordion {...props} />
            </Grid>
        </Grid>
    );
};

const ResultStatusField = (props) => {
    const { disabled, ...rest } = props;
    const limit = { from: 0, to: 6}
    const value = props.record[props.source];
    const disabledStatus = !isAdmin(props.permissions) || disabled;
    let choices = PurchaseOrderResultStatusChoices.filter(p => p.id > limit.from && p.id > limit.to);
    let excludedValue : {} | null = null;

    if (value >= limit.from && value <= limit.to) {
        excludedValue = PurchaseOrderResultStatusChoices.find(p => p.id === value) ?? null;
    }

    return (
        <StyledSelectInputOrderShow choices={choices} excludedValue={excludedValue} {...rest} disabled={disabledStatus} placeholder={ disabledStatus ? "<отсутствует>" : "Выбрать" }/>
    );
};

const AdditionalInfoAccordion = (props) => {
    const classes = useStyles();
    return <Accordion className={classes.orderAccorion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography><strong>Показать детали об аукционе</strong></Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.orderAccorionDetails}>
            <AdditionalInfo {...props} />
        </AccordionDetails>
    </Accordion>
}

const AdditionalInfo = (props) => {
    const classes = useStyles();
    return <Grid container className={classes.customPaper}>
        <Grid item className={classes.orderInnerItem}>
            <SimpleShowLayout {...props}>
                <DateField label="Дата публикации" source="publicationDate" />
                <DateField label="Дата начала подачи" source="filingDateStart" />
                <DateField label="Дата окончания подачи" source="filingDateEnd" />
                <DateField label="Дата рассм. заявки" source="applicationProcessingDate" />
                <DateField label="Дата проведения аукциона" source="tenderDate" />
                <ReferenceField source="customerId" label="Заказчик" reference={entities.customers}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="regionId" label="Регион" reference={entities.regions}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField label="Уполномоченный орган" source="designatedAuthority" />
                <TextField label="Виз ФЗ" source="federalLaw" />
                <TextField label="Вид извещения" source="orderType" />
                <TextField label="Кол-во уникальных МНН" source="innQuantity" />
                <SelectField label="Тип лота" source="lotType" choices={PurchaseOrderLotTypeChoices} />
            </SimpleShowLayout>
        </Grid>
        <Grid item className={classes.orderInnerItem}>
            <SimpleShowLayout {...props}>
                <CurrencyField label="Сумма НМЦ всего аукциона" source="totalPrice" />
                <CurrencyField
                    label={<Tooltip text={Texts.actualLotPriceSum} label="Сумма НМЦ позиций компании в аукционе" />}
                    source="actualLotPriceSum"
                />
                <PercentField label="Доля позиций компании в аукционе" source="actualLotPriceSumPercentage" />
                <CurrencyField
                    label={<Tooltip text={Texts.totalPriceFromPriceList} label="Сумма по прайс-листу позиций компании в аукционе" />}
                    source="totalPriceFromPriceList"
                />
                <CurrencyField
                    label={<Tooltip text={Texts.totalPriceFromCommercialOffer} label="Сумма в ценах КП 1" />}
                    source="totalPriceFromFirstCommercialOffer"
                />
                <CurrencyField
                    label={<Tooltip text={Texts.totalPriceFromCommercialOffer} label="Сумма в ценах КП 2" />}
                    source="totalPriceFromSecondCommercialOffer"
                />
                <CurrencyField
                    label={<Tooltip text={Texts.marginAmount} label="Сумма маржи_1" />}
                    source="firstCommercialOfferMarginAmount"
                />
                <CurrencyField
                    label={<Tooltip text={Texts.marginAmount} label="Сумма маржи_2" />}
                    source="secondCommercialOfferMarginAmount"
                />
                <PercentField
                    source="firstCommercialOfferMarginality"
                    label={<Tooltip text={Texts.marginalityOrder} label="Маржинальность_1" />}
                />
                <PercentField
                    source="secondCommercialOfferMarginality"
                    label={<Tooltip text={Texts.marginalityOrder} label="Маржинальность_2" />}
                />
                <PercentField
                    source="participantMarginShare"
                    label="Доля маржи участника ЭА"
                />
                <PercentField
                    source="supplierMarginShare"
                    label="Доля маржи поставщика в сумме маржи"
                />
            </SimpleShowLayout>
        </Grid>
        <Grid item className={classes.orderInnerItem}>
            <SimpleShowLayout {...props}>
                <NumberField
                    source="totalDrugVolume"
                    label={<Tooltip text={Texts.totalDrugVolume} label="Объем препаратов - м3" />}
                    options={{ maximumFractionDigits: 2 }}
                />
                <CurrencyField
                    label={<Tooltip text={Texts.logisticsAmount} label="Сумма логистики, руб. с НДС" />}
                    source="logisticsAmount"
                />
                <TextField label="Срок поставки по договору" source="deliveryTerm" />
            </SimpleShowLayout>
        </Grid>
    </Grid>
}

const LimitByParticipant = (props) => {    
    return (
        <ReferenceInput
            source={props.source}
            label={props.label}
            reference={entities.suppliers}
            disabled={props.disabled}
            onChange={createOnChangeWrapper(props)}
            options={{ InputProps: { disabled: props.disabled } }}
            filter={{ isSpecial: true, forbiddenParticipantId: props.record[props.participantSource] }}
            perPage={100}
            allowEmpty
        >
            <AutocompleteInputSingle source="name" />
        </ReferenceInput>
    );
}

export default OrderPart;