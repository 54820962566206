import React from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    closeIcon: {
        '& .MuiAutocomplete-endAdornment': {
            display: 'none'
        }
    }
});


const AutocompleteContactField = ({ value, setValue, label, onChange, options, error, helperText }) => {
    const getLabel = ({ firstName, lastName, email }: any) => `${firstName} ${lastName} ${email}`;
    const classes = useStyles();

    return <Autocomplete
        // @ts-ignore
        freeSolo
        getOptionLabel={getLabel}
        onChange={onChange}
        onKeyPress={e => e.key === "13" ? e.preventDefault() : null}
        options={options}
        inputValue={value}
        closeIcon={null}
        className={classes.closeIcon}
        renderInput={(params) => {
            const {inputProps, ...rest} = params;
            //@ts-ignore
            const { value: v, ...inputPropsWithoutValue } = inputProps;
            return (
            <TextField inputProps={{...inputPropsWithoutValue, value, autoComplete: 'new-password', form: {autoComplete: 'off'} }}
                {...rest}
                onChange={(e) => setValue(e.target.value)}
                label={label}
                margin="normal"
                error={error}
                helperText={error ? helperText : ""}
            />);
        }}
    />;
}

export default AutocompleteContactField;