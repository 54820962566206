import * as React from "react";
import { PurchaseOrderPriceApprovalCommonStatus } from "../common/enums";

export default ({ title, model: { status, firstCommercialOfferPrices, secondCommercialOfferPrices } }) => {    
    const needRenderApprove = status == null || status != PurchaseOrderPriceApprovalCommonStatus.OnApproval;

    return (
        <React.Fragment>
            {title}
            <br/>
            {renderOffers(firstCommercialOfferPrices, secondCommercialOfferPrices, needRenderApprove)}
        </React.Fragment>
    );
}

const renderOffers = (first, second, needRenderApprove) => {
    const result: any[] = [];

    const firstResult = renderPrices(first, "КП 1", needRenderApprove);
    if (firstResult != null) {
        result.push(firstResult);
        result.push(<br/>);
    }

    const secondResult = renderPrices(second, "КП 2", needRenderApprove);
    if (secondResult != null)
        result.push(secondResult);

    return result;
}

const renderPrices = (prices, title, needRenderApprove) => {
    if (prices == null || prices.length == 0)
        return null;

    return <React.Fragment>
        <span>{title}</span>
        <br/>
        { prices.map((p, idx) => renderPrice(p, idx == prices.length - 1, needRenderApprove)) }
    </React.Fragment>;
}

const renderPrice = (model, isLast, needRenderApprove) => {
    const { lotNumber, sku, quantity, price, priceFromPriceList, approved } = model;
    const renderApprove = () => {
        if (approved == null)
            return null;

        return <span> - <strong>{approved ? "согласовать" : "отказать" }</strong></span>
    }

    return <React.Fragment>
        <span>Лот {lotNumber}: <strong>{sku}</strong> в количестве {quantity} уп. по цене {formatCurrency(price)} при цене БП {formatCurrency(priceFromPriceList)} 
            {needRenderApprove && renderApprove()}</span>
        { !isLast && <br /> }
    </React.Fragment>
}

const format = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' });
const formatCurrency = (value) => format.format(value);
