import { config } from "./configuration";
import { setToken, deleteToken, getToken } from './utils';

export default {
    login: ({ username, password, captchaResult }) => {
        const request = new Request(config.authUrl, {
            method: 'POST',
            body: JSON.stringify({ username, password, captchaResult }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error('Неправильный логин или пароль');
                }
                return response.json();
            })
            .then(({ token, roles, fullName }) => {
                setAuthData(token, roles, fullName);
            });
    },
    logout: () => {
        clearAuthData();
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            clearAuthData();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return getToken() ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
        const role = getPermissions();
        return role ? Promise.resolve(role) : Promise.resolve([]);
    },
    getIdentity: () => {
        const fullName = getUserFullName();
        return { fullName };
    }
};

const getPermissions = () => localStorage.getItem('permissions');
export const getUserFullName = () => localStorage.getItem('fullName');

const clearAuthData = () => {
    deleteToken();
    localStorage.removeItem('permissions');
    localStorage.removeItem('fullName');
}

const setAuthData = (token, permissions, fullName) => {
    setToken(token);
    localStorage.setItem('permissions', permissions);
    localStorage.setItem('fullName', fullName);
}
