import { makeStyles } from '@material-ui/core';

export const useCommercialOfferStyles = makeStyles((theme) => ({
    COButton:{ 
        border: 'none',
        color: 'white',
        fontSize: '14px',
        lineHeight: '24px',
        background: '#009BC3',
        borderRadius: '6px !important',
        padding: '0 16px 0 20px !important',
        textTransform: 'none',
        marginBottom: 22,
        maxHeight: '40px',
        whiteSpace: 'nowrap',

        '&:hover': {
            background: '#009BC3',
            // color: 'rgba(0, 0, 0, 0.87)',
        },

        '& .MuiSvgIcon-root' : {
            width: 16
        },
    },
    COStatus: {
        color: '#323237',
        fontSize: '14px ',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Clarika Office Geometric',
        marginBottom: 10,

        '& > span:first-child':{
            width:17,
            height: 17,
            marginRight: 8
        },

        '& > span:last-child':{
            fontSize: '14px ',
            lineHeight: '20px',
        },
        
        '& .MuiSvgIcon-root': {
            width:'100%',
            height: '100%',
        }
    },    
    btnIcon : {
        '& .MuiSvgIcon-root' : {
            // transform: 'scale(0.8)'
        }
    },
    dialog: {
        // background: 'red'

        '& .MuiDialogTitle-root': {
            padding: '18px 16px',
            marginBottom: 24,
            
            '& .MuiTypography-root': {
                fontWeight: 'bold',
                fontSize: '18px',
                lineHeight: '24px',
            },
        },

        '& .MuiDialogContent-root' : {
            padding: '0px 40px',

            '& .MuiTypography-root': {
                fontSize: '16px',
                lineHeight: '20px',
                marginBottom: 24,
            }
        },

        '& .MuiInput-inputMultiline': {
            border: '1px solid #DCDCE1',
            boxSizing: 'border-box',
            borderRadius: 4,
            padding: 16

        },
        
        '& .MuiFormControl-root': {
            '& .MuiInput-underline': {
                borderBottomColor: '#DCDCE1'
            }
        },

        '& .MuiDialogActions-root': {
            padding: '0 40px',
            paddingBottom: '32px',
            marginTop: 20,
        }

    },

    LotRelevanceWrap: {
        display: 'flex',

        '& > span': {
            width: '100%',
            maxWidth: '220px',
            marginRight: 16,
            fontFamily: 'Clarika Office Geometric',
            fontSize: '16px',
            color: '#7B7F86'
        },

        '& .MuiButton-root': {
            textTransform: 'none',
            marginTop: 10,
            marginRight: 12,
            color: 'white',
            lineHeight: 1,
            padding: '3px 20px',

        },
        '& .MuiButton-containedPrimary': {
            backgroundColor: '#49C088',
        },
        '& .MuiButton-containedPrimary:disabled': {
            backgroundColor: '#818181',
        },
        '& .MuiButton-containedSecondary': {
            backgroundColor: '#FE7C8F',
        },
        '& .MuiButton-outlinedSecondary': {
            color: 'black',
            backgroundColor: '#F6F6F8',
        },
        '& .change': {
            color: '#009BC3',
            backgroundColor: 'rgba(0, 155, 195, 0.12);',
        }
    },
    LotPriceRelevanceButton: {
        padding: '0 16px 0 20px !important',
    },
    LotRelevanceStatus: {
        color: '#323237',
        fontSize: '14px ',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Clarika Office Geometric',
        marginBottom: 10,

        '& > span:first-child':{
            width:17,
            height: 17,
            marginRight: 8
        },

        '& > span:last-child':{
            fontSize: '14px ',
            lineHeight: '20px',
        },
        
        '& .MuiSvgIcon-root': {
            width:'17px',
            height: '17px',
            marginRight: 8,
        },

        
    },
    LotApprovePricesInfo: {
        color: '#7B7F86',
        fontSize: '16px',
        fontFamily: 'Clarika Office Geometric',
    },
    detailsHeaderInfo: {
        background: 'yellow',
        width: 'auto',
        maxHeight: '52px',
        backgroundColor: '#FFFBF1',
        border: '1px solid #FDD054',
        borderRadius: '8px',
        padding: '16px',
        display: 'flex',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        whiteSpace: 'nowrap',
        maxWidth: 'fit-content',

        '& .MuiTypography-root': {
            display: 'flex'
        },
        '& .warning-icon': {
            display: 'flex',
            color: '#FFA250',
            paddingRight: '6px',
            paddingLeft: '4px'
        },
    },
    blockOfText: {
        marginRight: '5px'
    }
}));
