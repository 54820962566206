import React from "react";
import {
    Datagrid, TextField
} from "react-admin";
import Pagination from "../../components/DefaultPagination";
import Filter from '../../components/GphcUIKit/Filter';
import List from "../../components/List";
import { AListActions } from "../../components/ListActions";
import { entities } from '../../configuration';
import { FactoryComponents } from '../../common/enums';

const SkuProducerList = props => {
    return (
        <List
            {...props}
            filters={
                <Filter
                    searchComponentOptions={[
                        { type: FactoryComponents.Search, source: "q", alwaysOn: true }
                    ]}
                    filtersComponentsOptions={[
                        { type: FactoryComponents.Sku, alwaysOn: true, sort:{ field: 'nomenclature', order: 'ASC' }, perPage: 300 },
                        { type: FactoryComponents.ReferenceAutocomplete, source: "producerId", label: "Производитель", reference: entities.producers, optionText: "name", alwaysOn: true, perPage: 200, sort:{ field: 'name', order: 'ASC' } },
                    ]}
                    {...props}
                />
            }
            perPage={25}
            pagination={<Pagination />}
            title={props.options.label}
            actions={<AListActions {...props} />}
            bulkActionButtons={false}
        >
            <Datagrid >
                <TextField source="skuName" label="Номенклатура" />
                <TextField source="producerName" label="Название производителя" />
            </Datagrid>
        </List>
    );
}


export default {
    list: SkuProducerList,
    // create: CustomerCreate
}