import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SearchInput } from "react-admin";

const useStyles = makeStyles((theme) => {
    return {
        customSearch: {
            // background: '#f0f'
            '& .MuiInputBase-root': {
                border: '2px solid #F7F7F8',
                borderRadius: 8
            }, 
            '& .MuiInputBase-input::placeholder':{
                color: '7B7F86'
            },
            '& .MuiInputBase-root .MuiInputAdornment-root': {
                order: -1
            },
            '& .MuiFilledInput-underline:after':{
                display: 'none'
            },
            '& .MuiFilledInput-underline:before': {
                display: 'none'
            },
            '& .MuiInputBase-input': {
                fontSize: 16
            }
        }
    }
});

const StyledSearchInput = (props) => {
    const classes = useStyles();
    return <SearchInput className={classes.customSearch} {...props}/>
}

export default StyledSearchInput