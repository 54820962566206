export interface BubbleObject {
    source: string,
    label: string,
    choices: string[]
}

interface BubbleObjects extends Array<BubbleObject> {}

export interface Bubbles {
  purchaseOrders: BubbleObjects,
  purchaseOrdersApproval: BubbleObjects,
  commercialOffers: BubbleObjects,
  skuPriceAdditionalContracts: BubbleObjects,
  skuPrices: BubbleObjects,
  skus: BubbleObjects,
  logisticsPrice: BubbleObjects,
  nomenclatures: BubbleObjects,
  regions: BubbleObjects,
  customers: BubbleObjects,
  skuRegionsRelevance: BubbleObjects,
  skuCustomersRelevance: BubbleObjects,
  maxWholesaleMarkups: BubbleObjects,
  stockBalance: BubbleObjects,
  skuProducers: BubbleObjects,
  suppliers: BubbleObjects,
  persistentLog: BubbleObjects,
  dictionaryImport: BubbleObjects,
  inns: BubbleObjects,
}
  
export interface BubblesState {
  bubbles: Bubbles
}

export const PUSH_BUBBLE_OBJECT = '@bubbles/PUSH_BUBBLE_OBJECT';

interface AddChoicesToBubbles {
  type: typeof PUSH_BUBBLE_OBJECT;
  payload: { key: string, choices: any };
}

export type BubblesActionsTypes =
| AddChoicesToBubbles;