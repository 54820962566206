import * as React from "react";
import AQB from "./aqb.client";

export class QueryBuilder extends React.Component {

    componentDidMount() {
        this.name = this.props.name;
    
        window.AQB = AQB;
        
        const opts = { language: "ru" };

        AQB.Web.UI.QueryBuilder(this.name, this.querybuilder, opts);
        AQB.Web.UI.ObjectTreeView(this.name, this.treeview);
        AQB.Web.UI.SubQueryNavigationBar(this.name, this.navbar);
        AQB.Web.UI.Canvas(this.name, this.canvas);
        AQB.Web.UI.StatusBar(this.name, this.statusbar);
        AQB.Web.UI.Grid(this.name, this.grid);
        AQB.Web.UI.SqlEditor(this.name, this.sql);
        AQB.Web.UI.CriteriaBuilder(this.name, this.criteriabuilder);

        AQB.Web.UI.startApplication('/api/queryBuilder/CreateQueryBuilder', this.name);
        AQB.Web.onCriteriaBuilderReady(this.subscribeToCriteriaBuilderChanges);
        AQB.Web.onQueryBuilderReady(this.subscribeToQueryBuilderChanges);

    };

    subscribeToQueryBuilderChanges = (qb) => {
        if (this.props.setStartQuery)
            this.props.setStartQuery(qb.SQL.replaceAll("\r", ""));
        
        if (this.props.updateHandler) {
            qb.on(qb.Events.SQLUpdated, () => this.props.updateHandler(qb.clientSQL, qb.SQL.replaceAll("\r", "")))
        }

        if (this.props.sqlTextHandler) {
            qb.on(qb.Events.SQLTextChanged, () => this.props.sqlTextHandler(qb.clientSQL, qb.SQL.replaceAll("\r", "")))
        }

        if (this.props.setQueryBuilder) {
            this.props.setQueryBuilder(qb);
        }
    }

    subscribeToCriteriaBuilderChanges = (cb) => {
        cb.loadColumns();
        cb.on(cb.Events.CriteriaBuilderChanged, () => this.onCriteriaBuilderChanged(cb));
    }

    onCriteriaBuilderChanged = (cb) => {
        cb.transformSql(function (sql) {
            AQB.Web.QueryBuilder.EditorComponent.setSql(sql);
        });
    }

    componentWillUnmount() {
        AQB.Web.dispose();
    };
    
      render() {
        return (
            <div>
                {this.props.createQB && 
                    <div>
                        <div id="qb" ref={el => this.querybuilder = el}></div>
                        <div className="qb-ui-layout">
                            <div className="qb-ui-layout__top">
                                <div className="qb-ui-layout__left">
                                    <div className="qb-ui-structure-tabs">
                                        <div className="qb-ui-structure-tabs__tab">
                                            <input type="radio" id="tree-tab" name="qb-tabs" defaultChecked />
                                            <label htmlFor="tree-tab">Database</label>
                                            <div className="qb-ui-structure-tabs__content">
                                            <div ref={el => this.treeview = el}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="qb-ui-layout__right">
                                    <div ref={el => this.navbar = el}></div>
                                    <div ref={el => this.canvas = el}></div>
                                    <div ref={el => this.statusbar = el}></div>
                                    <div ref={el => this.grid = el}></div>
                                </div>
                            </div>
                            <div className="qb-ui-layout__bottom">
                                <div ref={el => this.sql = el}></div>
                            </div>
                        </div>
                    </div>
                }

                {this.props.createCB &&
                    <div>
                        <div id="qb" ref={el => this.querybuilder = el}></div>
                        <div id="cb" ref={el => this.criteriabuilder = el}></div>
                        <div style={{visibility: "hidden", position: "absolute"}} className="qb-ui-layout__bottom">
                                <div ref={el => this.sql = el}></div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
