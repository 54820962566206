import React from 'react';
import { ReferenceArrayInput, ReferenceInput, BooleanInput, ExportButton, useListContext } from 'react-admin';
import { entities } from '../../configuration'
import SelectInput from "../../components/GphcUIKit/SelectInput";
import AutocompleteInput from "../../components/AutocompleteInput";
import DateInputMaterialKeyboard from "../GphcUIKit/DateInputMaterialKeyboard";
import NullableBooleanInput from "../../components/GphcUIKit/NullableBooleanInput";
import StyledSearchInput from '../../customTheme/StyledSearchInput';
import QuickFilter from "../../components/QuickFilter";
// button
import { FlatButton, PrimaryButton, SecondaryButton } from "../GphcUIKit/Button";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


class FilterFactory {
    fMapper: FactoryMapper;
    constructor() {
        this.fMapper = new FactoryMapper();
    }

    create(props) {
        if(!props.type)
            return null;

        const { type, permissions, roles } : { type: string, permissions: string, roles: string[] } = props;
        if(roles && roles.length !== 0) {
            if(roles.some(r => permissions === r)){
                const factory = this.fMapper.factory(type);
                return factory.create({...props});
            }
            else
                return null;
        }

        const factory = this.fMapper.factory(type);
        return factory.create({...props});
      }
};

export default FilterFactory;

class FactoryMapper {
    factories: object = {};

    constructor() {
        const innFilterFactory = new InnFilterFactory();
        const customerFilterFactory = new CustomerFilterFactory();
        const federalDistrictFilterFactory = new FederalDistrictFilterFactory();
        const regionFilterFactory = new RegionFilterFactory();
        const areaOfResponsibilityFilterFactory = new AreaOfResponsibilityFilterFactory();
        const referenceInputSelectFilterFactory = new ReferenceInputSelectFilterFactory();
        const skuFilterFactory = new SkuFilterFactory();
        const supplierFilterFactory = new SupplierFilterFactory();
        const dateInputFactory = new DateInputFactory();
        const selectInputFactory = new SelectInputFactory();
        const referenceInputAutocompleteFactory = new ReferenceInputAutocompleteFactory();
        const searchInputFactory = new SearchInputFactory();
        const quickFilterFactory = new QuickFilterFactory();
        const nullBooleanFactory = new NullBooleanFactory();
        const customFilterTopBarFactory = new CustomFilterTopBarFactory();
        const booleanFieldFilterFactory = new BooleanFieldFilterFactory();

        this.factories[innFilterFactory.type] = innFilterFactory;
        this.factories[customerFilterFactory.type] = customerFilterFactory;
        this.factories[federalDistrictFilterFactory.type] = federalDistrictFilterFactory;
        this.factories[regionFilterFactory.type] = regionFilterFactory;
        this.factories[areaOfResponsibilityFilterFactory.type] = areaOfResponsibilityFilterFactory;
        this.factories[referenceInputSelectFilterFactory.type] = referenceInputSelectFilterFactory;
        this.factories[skuFilterFactory.type] = skuFilterFactory;
        this.factories[supplierFilterFactory.type] = supplierFilterFactory;
        this.factories[dateInputFactory.type] = dateInputFactory;
        this.factories[selectInputFactory.type] = selectInputFactory;
        this.factories[referenceInputAutocompleteFactory.type] = referenceInputAutocompleteFactory;
        this.factories[searchInputFactory.type] = searchInputFactory;
        this.factories[quickFilterFactory.type] = quickFilterFactory;
        this.factories[nullBooleanFactory.type] = nullBooleanFactory;
        this.factories[customFilterTopBarFactory.type] = customFilterTopBarFactory;
        this.factories[booleanFieldFilterFactory.type] = booleanFieldFilterFactory;
    }

    factory = ( type: string ): IFilterFactory => type && this.factories[type];
};

interface IFilterFactory {
    readonly type: string;
    
    create(props): JSX.Element;
};

class InnFilterFactory implements IFilterFactory {
    readonly type = 'inn';
    
    create(props) {
        return (
            <ReferenceInputSelectComponent
                filter={{ actual: true }}
                source="innId"
                sort={{ field: 'name', order: 'ASC' }}
                label="МНН" 
                perPage={50}
                reference={entities.inns} 
                inputSource="name"
                {...props}
            />
        );
    }
};

class CustomerFilterFactory implements IFilterFactory {
    readonly type = 'customer';
    
    create(props) {
        return (
            <ReferenceArrayInputAutocompleteComponent 
                source="customerId" 
                label="Заказчик" 
                reference={entities.customers}
                optionText={c => `${c.name} ${c.inn ? c.inn : ""}`} 
                inputSource="name"
                {...props}
            />
        );
    }
};

class ReferenceInputSelectFilterFactory implements IFilterFactory {
    readonly type = 'referenceInputSelect';
    
    create(props) {
        return (
            <ReferenceInputSelectComponent 
                {...props}
            />
        );
    }
};

class BooleanFieldFilterFactory implements IFilterFactory {
    readonly type = 'booleanField';
    
    create(props) {
        return (
            <BooleanInput 
                {...props}
            />
        );
    }
};

class FederalDistrictFilterFactory implements IFilterFactory {
    readonly type = 'federalDistrict';
    
    create(props) {
        return (
            <ReferenceInputSelectComponent 
                source="federalDistrictId" 
                label="Федеральный округ" 
                reference={entities.federalDistricts} 
                inputSource="name"
                {...props}
            />
        );
    }
};

class RegionFilterFactory implements IFilterFactory {
    readonly type = 'region';

    create(props) {
        return (
            <ReferenceArrayInputAutocompleteComponent 
                source="regionId" 
                label="Регион" 
                reference={entities.regions} 
                inputSource="name"
                {...props}
            />
        );
    }
};

class SupplierFilterFactory implements IFilterFactory {
    readonly type = 'supplier';

    create(props) {
        return (
            <ReferenceArrayInputAutocompleteComponent 
                source="supplierId" 
                label="Поставщик" 
                reference={entities.suppliers} 
                inputSource="name"
                {...props}
            />
        );
    }
};

class AreaOfResponsibilityFilterFactory implements IFilterFactory {
    readonly type = 'areaOfResponsibility';
    
    create(props) {
        return (
            <ReferenceInputSelectComponent 
                source="areaOfResponsibilityId" 
                label="Зона ответсвенности" 
                reference={entities.areasOfResponsibility} 
                inputSource="name"
                {...props}
            />
        );
    }
};

class SkuFilterFactory implements IFilterFactory {
    readonly type = 'sku';

    create(props) {
        return (
            <ReferenceArrayInputAutocompleteComponent 
                source="skuId" 
                label="SKU" 
                reference={entities.skus} 
                optionText="nomenclature"
                {...props}
            />
        );
    }
};

class DateInputFactory implements IFilterFactory {
    readonly type = 'date';

    create({ source, label, ...rest }) {
        return (
            <DateInputComponent
                source={source} 
                label={label} 
                inputSource="date"
                {...rest}
            />
        );
    }
};

class SelectInputFactory implements IFilterFactory {
    readonly type = 'select';

    create({ source, label, choices, useLabelAsPlaceholder = true, ...rest} : {source : string, label: JSX.Element | string | null, choices: [], useLabelAsPlaceholder: boolean}) {
        return (
            <SelectInputComponent
                source={source}
                label={label}
                choices={choices}
                useLabelAsPlaceholder={useLabelAsPlaceholder}
                {...rest}
            />
        );
    }
};

class ReferenceInputAutocompleteFactory implements IFilterFactory {
    readonly type = 'referenceAutocomplete';

    create(props) {
        return <ReferenceArrayInputAutocompleteComponent {...props} /> ;
    }
}

class SearchInputFactory implements IFilterFactory {
    readonly type = 'search';

    create(props) {
        return <SearchInputComponent {...props}/> ;
    }
}

class QuickFilterFactory implements IFilterFactory {
    readonly type = 'quick';

    create(props) {
        return <QuickFilterComponent {...props} /> ;
    }
}

class NullBooleanFactory implements IFilterFactory {
    readonly type = 'nullBoolean';

    create(props) {
        return <NullBooleanComponent {...props} /> ;
    }
}

class CustomFilterTopBarFactory implements IFilterFactory {
    readonly type = 'customFilterTopBar'

    create(props) {
        return <CustomFilterTopBarComponent {...props} />;
    }
}

const ReferenceInputSelectComponent = ({ source, label, reference, inputSource, optionText, isNotDefaultColor = true, ...rest }) => (
    <ReferenceArrayInput source={source} label={label} reference={reference} InputLabelProps={{ shrink: true }} {...rest} >
        <SelectInput source={source} optionText={optionText} isNotDefaultColor useLabelAsPlaceholder />
    </ReferenceArrayInput>
);

const ReferenceArrayInputAutocompleteComponent = ({ source, label, reference, inputSource, isNotDefaultColor = true, optionText, ...rest }) => (
    <ReferenceArrayInput source={source} label={label} reference={reference} InputLabelProps={{ shrink: true }} {...rest}>
        <AutocompleteInput source={source} optionText={optionText} isNotDefaultColor useLabelAsPlaceholder filtersMode allowEmpty={false}/>
    </ReferenceArrayInput>
);

const DateInputComponent = (props) => (
    <DateInputMaterialKeyboard {...props} />
);

const NullBooleanComponent = ({ source, label, useLabelAsPlaceholder = true, isNotDefaultColor = true, ...rest}) => (
    <NullableBooleanInput source={source} label={label} InputLabelProps={{ shrink: true }} isNotDefaultColor useLabelAsPlaceholder {...rest}/>
);

const SelectInputComponent = ({ source, label, useLabelAsPlaceholder = true, isNotDefaultColor = true, choices, ...rest}) => (
    <SelectInput source={source} label={label} choices={choices} InputLabelProps={{ shrink: true }} isNotDefaultColor useLabelAsPlaceholder {...rest}/>
);

// По макету надо было добавить кнопку справа от компонента поиска
// Архитектурно получилось эту задачу таким образом - имеем поиск и кнопку тогглер в блоке сверху
// Остальные фильтры должны находиться в блоке снизу
// Также дефолтный layout react-admin добавляет блок экшенов как отдельный блок СПРАВА, поэтому все фильтры могут иметь не 100% ширину, однако по макету требуется иметь 100%
// Аналогичным путем решения проблемы является задание position: absolute, actions кнопкам. Тогда ширина блока будет 100%
const CustomFilterTopBarComponent = ({ isToggled, changeTogglerState, pathname, source, total, resource, ...rest }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {/* search input */}
            <StyledSearchInput source={source} {...rest}  style={{ width: '301px' }}/>
            <div style={{ width: 'calc(100% - 301px)', display: 'flex', justifyContent: 'space-between' }}>
                {/* toggler filters */}
                <FlatButton onClick={() => changeTogglerState(pathname)}>
                    <span style={ { display: 'flex', width: '157px', justifyContent: 'center' } }>
                        { isToggled[pathname] ? <>Скрыть фильтры <KeyboardArrowUpIcon /></> : <>Показать фильтры <KeyboardArrowDownIcon /></> }
                    </span>
                </FlatButton>
            </div>            
        </div>
    );
};

const SearchInputComponent = ({isToggled, changeTogglerState, pathname, source, total, resource, ...rest}) => {
    return <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {/* search input */}
            <StyledSearchInput source={source} {...rest}  style={{ width: '301px' }}/>
            <div style={{ width: 'calc(100% - 301px)', display: 'flex', justifyContent: 'space-between' }}>
                {/* toggler filters */}
                <FlatButton onClick={() => changeTogglerState(pathname)}>
                    <span style={ { display: 'flex', width: '157px', justifyContent: 'center' } }>
                        { isToggled[pathname] ? <>Скрыть фильтры <KeyboardArrowUpIcon /></> : <>Показать фильтры <KeyboardArrowDownIcon /></> }
                    </span>
                </FlatButton>
            </div>            
        </div>
};

const QuickFilterComponent = ({label, source, defaultValue, ...rest}) => (
    <QuickFilter label={label} source={source} defaultValue={defaultValue} {...rest}/>
);
