import * as React from 'react';
import { TextField as RaTextField } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    orderRelevance: {
        color: '#323237',
        fontSize: '14px ',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',

        '& > span:first-child':{
            width:17,
            height: 17,
            marginRight: 8
        },

        '& > span:last-child':{
            fontSize: '14px ',
            lineHeight: '20px',
        },
        
        '& .MuiSvgIcon-root': {
            width:'100%',
            height: '100%',
        }
    },

}) );

const TextFieldWithIcon = ({ success, ...rest}) => {
    const classes = useStyles();

    return <div className={classes.orderRelevance}>
        <span >{rest.icon}</span>
        <RaTextField {...rest} />
    </div>
};

export default TextFieldWithIcon;
