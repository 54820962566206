import * as React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    chip: {
        marginLeft: '7px',
        padding: '1px',
        color: 'white',
        backgroundColor: '#009BC3',
    },
}));

const ChipListField = (props) => {
    const { record, source, toValue } = props;
    const classes = useStyles();

    if (record == null || source == null)
        return null;

    const value = record[source];
    if (value == null)
        return null;
    
    return <React.Fragment>
        {(toValue ? value.map(i => toValue(i)) : value).map(i => <Chip className={classes.chip} label={i} />)}
    </React.Fragment>;
};

ChipListField.addLabel = {
    addLabel: true,
};

export default ChipListField;
