import * as React from "react";
import {
    BooleanField, Datagrid, DateField, ReferenceField,
    TextField
} from "react-admin";
import { IsActualStatusChoices, IsVeDrugChoices } from '../../common/choices';
import { useGridFixedColumnStyles } from "../../common/helpers";
import useAdditionalFilterValues from "../../common/useAdditionalFilterValues";
import CurrencyField from "../../components/CurrencyField";
import Pagination from "../../components/DefaultPagination";
import Filter from '../../components/GphcUIKit/Filter';
import List from "../../components/List";
import { AListActions } from "../../components/ListActions";
import ListWithSideBar from "../../components/ListWithSideBar";
import PercentField from "../../components/PercentField";
import { entities } from "../../configuration";
import api from "../../dataProvider/api";
import Show from './SkuPriceShow';
import { FactoryComponents } from '../../common/enums';
import ChipListField from "../../components/GphcUIKit/ChipListField";

const SkuPriceList = props => {
    if (props.basePath === "/skuPriceAdditionalContracts")
        return <SkuAdditionalContractsGrid {...props} />;

    return (<SkuPriceGrid {...props} />);
}

const SkuAdditionalContractsGrid = (props) => {
    const classes = useGridFixedColumnStyles(1);
    const filterValues = useAdditionalFilterValues(api.getSkuPriceFilterValues);

    return (
        <List
            {...props}
            pagination={<Pagination />}
            perPage={25}
            filters={<Filter
                searchComponentOptions={[
                    { type: FactoryComponents.Search, source: "q", alwaysOn: true }
                ]}
                dateComponentsOptions={[
                    { type: FactoryComponents.Date, source: "dateActualTo", label: "Актуальность на", alwaysOn: true },
                ]}
                filtersComponentsOptions={[
                    { type: FactoryComponents.Sku, alwaysOn: true, sort:{ field: 'nomenclature', order: 'ASC' }, perPage: 300 },
                    { type: FactoryComponents.ReferenceInputSelect, source: "innId", label: "МНН", reference: entities.inns, inputSource: "name", alwaysOn: true, filter: { actualBySkuPrice: true }, sort:{ field: 'name', order: 'ASC' }, perPage: 300  },
                    { type: FactoryComponents.ReferenceAutocomplete, source: "accountingProducerId", label: "Производитель", reference: entities.accountingProducers, inputSource: "name", alwaysOn: true },
                    { type: FactoryComponents.ReferenceAutocomplete, source: "accountingSupplierId", label: "Поставщик", reference: entities.accountingSuppliers, inputSource: "name", alwaysOn: true },
                    { type: FactoryComponents.Select, source: "isVeDrug", choices: IsVeDrugChoices, label: "Тип ЖНВЛС", alwaysOn: true, isMultiple: false },
                    { type: FactoryComponents.Select, source: "actual", choices: IsActualStatusChoices, label: "Актуальность", alwaysOn: true, isMultiple: false },
                    ...filterValues
                ]}
                {...props}
            />}
            filter={{ history: false }}
            title={props.options.label}
            actions={<AListActions {...props} />}
            bulkActionButtons={false}
        >
            <Datagrid rowClick={null} className={classes.dataGrid}>
                <ReferenceField source="skuId" reference={entities.skus} label="SKU" link="show">
                    <TextField source="nomenclature" />
                </ReferenceField>
                <TextField source="accountingProducerName" label="Производитель" />
                <TextField source="accountingSupplierName" label="Поставщик" />
                <CurrencyField source="purchasingPriceWithMarkupRatio" label="Закупочная цена с НДС (до вычета КН)" />
                <CurrencyField source="purchasingPriceWithoutMarkupRatio" label="Закупочная цена с НДС (после вычета КН)" />
                <TextField source="markupRatio" label="Размер КН, %" />
                <TextField source="additionalContractNumber" label="Номер ДС" />
                <DateField source="additionalContractValidFrom" label="Дата начала" showTime />
                <DateField source="additionalContractValidTo" label="Дата окончания" showTime />
                <TextField source="comments" label="Комментарии" />
            </Datagrid>
        </List>
    );
}

const SkuPriceGrid = (props) => {
    const classes = useGridFixedColumnStyles(1);
    const filterValues = useAdditionalFilterValues(api.getSkuPriceFilterValues);

    return (
        <ListWithSideBar
            {...props}
            show={<Show />}
            list={
                <List
                    {...props}
                    onKeyPress={props.handleClose}
                    filters={
                        <Filter
                            searchComponentOptions={[{ type: FactoryComponents.Search, source: "q", alwaysOn: true }]}
                            dateComponentsOptions={[{ type: FactoryComponents.Date, source: "dateActualTo", label: "Актуальность на", alwaysOn: true }]}
                            filtersComponentsOptions={[
                                { type: FactoryComponents.Sku, alwaysOn: true, sort:{ field: 'nomenclature', order: 'ASC' }, perPage: 300 },
                                { type: FactoryComponents.ReferenceInputSelect, source: "innId", label: "МНН", reference: entities.inns, inputSource: "name", alwaysOn: true, filter: { actualBySkuPrice: true }, sort:{ field: 'name', order: 'ASC' }, perPage: 300  },
                                { type: FactoryComponents.ReferenceAutocomplete, source: "accountingProducerId", label: "Производитель", reference: entities.accountingProducers, inputSource: "name", alwaysOn: true },
                                { type: FactoryComponents.ReferenceAutocomplete, source: "accountingSupplierId", label: "Поставщик", reference: entities.accountingSuppliers, inputSource: "name", alwaysOn: true },
                                { type: FactoryComponents.Select, source: "isVeDrug", choices: IsVeDrugChoices, label: "Тип ЖНВЛС", alwaysOn: true, isMultiple: false },
                                { type: FactoryComponents.Select, source: "actual", choices: IsActualStatusChoices, label: "Актуальность", alwaysOn: true, isMultiple: false },
                                ...filterValues
                            ]}
                            {...props} />
                    }
                    pagination={<Pagination />}
                    perPage={25}
                    filter={{ history: false }}
                    title={props.options.label}
                    actions={<AListActions {...props} />}
                    bulkActionButtons={false}
                >
                    <Datagrid rowClick="show" className={classes.dataGrid}>
                        <ReferenceField source="skuId" reference={entities.skus} label="SKU" link="show">
                            <TextField source="nomenclature" />
                        </ReferenceField>
                        <TextField source="key" label="Код SKU" />
                        <TextField source="measurementUnits" label="Единицы измерения" />
                        <TextField source="innName" label="МНН" />
                        <TextField source="pharmaceuticalGroup" label="Фарм. группа" />
                        <TextField source="accountingProducerName" label="Производитель" />
                        <TextField source="accountingSupplierName" label="Поставщик" />
                        <BooleanField label="Актуальность" source="isActual" sortable={false} />
                        <BooleanField source="isVeDrug" label="Тип ЖНВЛП" />
                        <DateField source="validFrom" label="Дата загрузки" />
                        <CurrencyField source="vitalDrugsPrice" label="Цена реестра, руб. без НДС" />
                        <PercentField source="vatAmount" label="% НДС" />
                        <CurrencyField source="purchasingPriceWithMarkupRatio" label="Закупочная цена с НДС (до вычета КН)" />
                        <CurrencyField source="purchasingPriceWithoutMarkupRatio" label="Закупочная цена с НДС (после вычета КН)" />
                        <TextField source="markupRatio" label="Размер КН, %" />
                        <CurrencyField source="planningSellingPrice" label="Плановая продажная цена с НДС" />
                        <PercentField source="planningProfitability" label="Плановая рентабельность, %" />
                        <CurrencyField source="minPrice" label="Мин. цена дна с НДС" />
                        <PercentField source="minPriceProfitability" label="Плановая рентабель-ность при min цене, %" />
                        <BooleanField source="isInnAdditional" label="Дополнительное SKU" />
                        <ChipListField source="mainInns" label="Привязка к основному МНН" />
                    </Datagrid>
                </List>
            }
        />
    );
}


export default {
    list: SkuPriceList
}