import * as React from 'react';
import { FunctionField } from 'react-admin'

const toPercents = (value) => value != null ? `${value.toFixed(1)}%` : null;

const PercentField = (props) => <FunctionField {...props} render={r => toPercents(r[props.source])} />;

PercentField.defaultProps = {
    addLabel: true,
};

export default PercentField;
