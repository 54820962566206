// react core
import React from 'react'
// material-ui components
import { Chip as ChipMaterial } from '@material-ui/core';
// material-ui icon
import ClearIcon from '@material-ui/icons/Clear';
// redux connect
import { connect} from 'react-redux';

const FilterBubbles = (props) => {
    // redux state
    const { resources } = props;
    // current router with finding local state in redux admin
    const { pathname } = props;
    const localResources = resources[pathname];
    const { bubblesModule } = props;
    const localBubbles = bubblesModule.bubbles[pathname];
    // make object without reference in memory to make work setFilters on other pages
    const filters = { ...localResources?.list?.params?.filter };
    if (filters == null)
        return null;
    // props из родительского компонента
    const { setFilters } = props;

    const handleDelete = (filterKey, type, index) => {
        if (type === 'multiple')
            filters[filterKey].splice(index, 1);

        if (type === 'single')
            delete filters[filterKey];

        setFilters(filters);
    };

    const findSource = (source) => {
        return localBubbles.find(localBubble => localBubble.source === source);
    }

    const findIdInChoices = (choices, id) => {
        return choices.length > 0 ? choices.find(choice => choice.id === id) : id;
    }

    const makeLabelString = (source, id) => {
        if (source == 'q')
            return `Поиск: ${id}`;

        const foundObject = findSource(source);
        if (foundObject) {
            const foundChoice = findIdInChoices(foundObject.choices, id);
            if (foundChoice == null)
                return null;

            const label = foundObject.label;
            const name = tryGetDate(foundChoice) || foundChoice.name || foundChoice.fullName || foundChoice.nomenclature || foundChoice;            
            return `${label}: ${name}`
        }
    }

    const tryGetDate = (value) => {
        if (value instanceof Date)
            return value.toLocaleDateString('ru-RU');

        return null;
    }

    return (
        <div style={{ marginTop: '16px', marginBottom: '10px' }}>
            {
            Object.entries(filters).map((filter, i) => {
                const source = filter[0];
                const name = filter[1];
                    if (Array.isArray(name)) {
                        return name.map((wrappedName, wrappedI) => {
                            return <ChipMaterial
                                key={wrappedI}
                                // filter[0] is source of select input
                                // filter[1] is index in array of choices
                                label={makeLabelString(source, wrappedName)}
                                onDelete={() => handleDelete(source, 'multiple', wrappedI)}
                                deleteIcon={<ClearIcon />}
                                color="primary"
                                style={{ marginRight: '16px', marginBottom: '39px' }}
                            /> 
                        }) 
                    } else {
                        // проверка на дефолтное значение которое нельзя удалить
                        const label = makeLabelString(source, name);
                        if (label) {
                            return <ChipMaterial
                                key={i}
                                // filter[0] is source of select input
                                // filter[1] is index in array of choices
                                label={label}
                                onDelete={() => handleDelete(source, 'single', null)}
                                deleteIcon={<ClearIcon />}
                                color="primary"
                                style={{ marginRight: '16px', marginBottom: '39px' }}
                            /> 
                        }
                    }   
            })
            }
        </div>
    );
}

function mapStateToProps(state) {
    const { admin, router, bubbles } = state
    // pathname === '/myPath' --> 'myPath'
    return { resources: admin.resources, pathname: router.location.pathname.split('/')[1], bubblesModule: bubbles }
}

export default connect(mapStateToProps, null)(FilterBubbles)
