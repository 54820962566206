import * as React from 'react';
import Typography from '@material-ui/core/Typography';

const LabeledField = (props) => {
    const { label, children } = props;    

    return <div style={{display: 'flex', justifyContent: 'space-between'}}>
        {label && <Typography style={{float: 'left', color: '#e1e1e3'}} variant="subtitle1">{label}</Typography>}
        {children && React.cloneElement(children, {...props, style: {marginLeft: 'auto', color: '#646469'}})}
    </div>;
};

export default LabeledField;
